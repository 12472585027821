import { useEffect, useState } from 'react'

const values = new Map<symbol, number>()

function getStoredValue(key: symbol, targetValue: number): number {
  let value = values.get(key)
  if (typeof value === 'undefined') {
    value = targetValue
    values.set(key, targetValue)
  }
  return value
}

function setStoredValue(key: symbol, value: number): void {
  values.set(key, value)
}

/**
 * This hook helps with the progressbar animation by making
 * sure a new instance of the progress bar, is always rendered
 * with the old value, before changing the value.
 * This is needed to make the animation work properly across page changes.
 *
 * @param key: uniquely identify logical instances of the progressbar
 * @param targetValue: the value we want to have in the end
 */
export function useTransitionHelper(key: symbol, targetValue: number): number {
  const [value, setValue] = useState<number>(getStoredValue(key, targetValue))

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setStoredValue(key, targetValue)
      setValue(targetValue)
    }, 10)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [key, targetValue])

  return value
}

export const defaultProgressBarHeight = 4
