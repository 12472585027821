import { success, type Result } from '@orus.eu/result'
import { type } from 'arktype'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export class ActivitiesDistributionDimension<NAME extends string> extends AbstractDimension<
  NAME,
  ActivitiesDistribution
> {
  override validateData(value: LooselyTypedValue): Result<ActivitiesDistribution, DimensionValidationProblem> {
    const parsingResult = ActivitiesDistributionType(value)

    if (parsingResult instanceof type.errors) {
      return dimensionValidationFailure(`Field ${this.name} is not valid`)
    }

    return success(parsingResult)
  }
}

export const ActivitiesDistributionType = type({
  type: "'single'",
}).or({
  type: "'multiple'",
  distribution: {
    '[string]': {
      sharePercentage: '1<=number<=100',
    },
  },
})

export type ActivitiesDistribution = typeof ActivitiesDistributionType.infer
