import { assert } from '@orus.eu/error'
import { AbstractDimension, type DimensionOptions } from './abstract-dimension.js'

export class OptionsListDimension<Name extends string, Option extends string> extends AbstractDimension<
  Name,
  readonly Option[]
> {
  constructor(options: DimensionOptions<Name>) {
    assert(options.name.endsWith('s'), 'dimension name must be in plural form for template tags consistency')
    super(options)
  }
}
