import styled from '@emotion/styled'
import { Children, cloneElement, memo, useEffect, useRef, type ReactElement } from 'react'
import { borderStroke, colorTokens, spacing } from '../../foundation'
import { type OrusTabSize, type TabProps } from './tab'

type TabbarProps = {
  children: ReactElement<TabProps>[]
  size: OrusTabSize
  onClick: (index: number) => void
  activeTabIndex: number
}

export const Tabbar = memo<TabbarProps>(function Tabbar(props) {
  const { children, size, onClick, activeTabIndex } = props
  const activeTabRef = useRef<HTMLDivElement | null>(null)
  const tabIndicatorRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    // This useEffect is here for the animation purpose. We dynamically update the tabIndicator width and position so
    // it animates (combined with the transition css property). The 'activeTabIndex' and 'children' dependency is important
    // to update the values when needed.
    if (!tabIndicatorRef || !tabIndicatorRef.current) return
    const tabRect = activeTabRef.current?.getBoundingClientRect()
    const tabsRect = activeTabRef.current?.parentElement?.getBoundingClientRect()

    tabIndicatorRef.current.style.width = `${tabRect?.width}px`
    tabIndicatorRef.current.style.transform = `translateX(${tabRect && tabsRect ? tabRect.left - tabsRect.left : 0}px)`
  }, [activeTabIndex, children])

  return (
    <StyledTabbarContainer role="tablist">
      {Children.map(children, (child, index) =>
        cloneElement(child as React.ReactElement<TabProps & { ref?: React.Ref<HTMLDivElement> }>, {
          onClick: () => onClick(index),
          active: index === activeTabIndex,
          size: size,
          ref: index === activeTabIndex ? activeTabRef : undefined,
        }),
      )}
      <StyledActiveTabIndicator ref={tabIndicatorRef} />
    </StyledTabbarContainer>
  )
})

const StyledTabbarContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: ${spacing[40]};
`

const StyledActiveTabIndicator = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: ${borderStroke[30]};
  background-color: ${colorTokens['color-bg-base-active']};

  transition:
    transform 0.2s ease-in-out,
    width 0.2s ease-in-out;
`
