import { css } from '@emotion/react'
import { Box, type SxProps } from '@mui/material'
import { Icon, Spinner, borderRadius, colors, shadow } from '@orus.eu/pharaoh'
import { useEffect, useState } from 'react'
import { closeHubspotChat, hubspotWidgetOpen, hubspotWidgetWrapperId } from '../../lib/hubspot-util'
import { useLargeScreen } from '../../lib/use-large-screen'
import { UnstyledButton } from '../atoms/unstyled-button'

const spinnerZIndex = 0
const hubspotIframeZIndex = 1
const closeButtonZIndex = 2

const commonIFrameStyle: SxProps = {
  width: '100%',
  height: '100%',
  border: 'none',
  position: 'relative',
  zIndex: hubspotIframeZIndex,
}

const commonSx: SxProps = {
  backgroundColor: colors.white,
  position: 'absolute',
  '& #hubspot-conversations-inline-parent': {
    width: '100%',
    height: '100%',
  },
  '& #hubspot-conversations-inline-parent iframe': commonIFrameStyle,
}

const openSmallScreenSx: SxProps = {
  ...commonSx,
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',
}

const openLargeScreenSx: SxProps = {
  ...commonSx,
  right: '40px',
  width: '375px',
  height: '600px',
  top: '100px',
  boxShadow: shadow.bottom[40],
  borderRadius: borderRadius[20],
  '& #hubspot-conversations-inline-parent iframe': {
    ...commonIFrameStyle,
    borderRadius: borderRadius[20],
  },
}

const closedSx: SxProps = { ...commonSx, display: 'none' }

export default function HubspotWidgetWrapper(): JSX.Element {
  const [open, setOpen] = useState(hubspotWidgetOpen.getValue())
  const largeScreen = useLargeScreen()

  useEffect(() => hubspotWidgetOpen.subscribe(setOpen), [setOpen])

  const sx = open ? (largeScreen ? openLargeScreenSx : openSmallScreenSx) : closedSx

  return (
    <Box id={hubspotWidgetWrapperId} sx={sx}>
      {open ? (
        <UnstyledButton
          sx={{
            position: 'absolute',
            right: '0px',
            top: '0',
            padding: '22px',
            zIndex: closeButtonZIndex,
          }}
          onClick={closeHubspotChat}
        >
          <Icon icon="xmark-solid" color={colors.white} />
        </UnstyledButton>
      ) : (
        <></>
      )}
      <Spinner
        size="60"
        css={css`
          position: absolute;
          top: calc(50% - 24px);
          left: calc(50% - 24px);
          z-index: ${spinnerZIndex};
        `}
      />
    </Box>
  )
}
