import { z } from 'zod'

/**
 * The product is the biggest high level blocks the user (or sales) choses between. They are the only
 * thing that can be sold standalone.
 * - When customizing the subscription, the sales people first chose one or more “products”
 * - The “products” are the highest level of customization and drive :
 *     - Which risk carriers (and which partnerships with each of the risk carriers) will be used
 *     - What additional data we need
 * - The “products” can be sold standalone. If it doesn’t make sense to send something alone, it’s not a product, but an option of another product.
 * - Each product can have it’s options which triggers additional questions, at any level of depth, but the highest level deserves a special attention because it frames the discussion between Orus and the customer.
 *
 * For now we have 3 products :
 *
 * - **rcph** for “RC Pro Hiscox” (TECH, MAC, MAN, etc.)
 * - **mrph** for “MRP office Hiscox”
 * - **mrpw** for “MRP Wakam” (doesn’t include restaurants for now, as they can only be subscribed with the restaurants policy, but it could in the future if we decide so)
 * - no values are defined for legacy policies as of May 23, 2023
 *
 * See https://www.notion.so/orus-team/Technical-concepts-as-of-00ffc0d93c924f21b894a0a8407ed801 for more details
 */
export type Product = (typeof allProducts)[number]
export const allProducts = ['mrph', 'mrpw', 'rcph', 'muta', 'rcda', 'es-rcph'] as const

export const productZodSchema = z.enum(allProducts)
