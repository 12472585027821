import { RouterProvider } from '@tanstack/react-router'
import { Suspense, memo, useCallback } from 'react'
import 'simplebar-react/dist/simplebar.min.css'

import { PharaohWrapper, ToastProvider, type ButtonClickContext } from '@orus.eu/pharaoh'
import { TemporaryNotificationAlertProvider } from '@orus.eu/pharaoh/src/components/callout-notification/notification/alert'
import { isSuccess } from '@orus.eu/result'
import { GlobalLoadingState } from './components/molecules/global-loading-state'
import { EmbeddingPartnerWrapper } from './components/templates/embedding-partner-wrapper'
import { SessionLanguageProvider } from './components/templates/session-language-provider'
import { SessionProvider } from './components/templates/session-provider'
import { sendMessage } from './lib/tracking/tracking'
import { getPageNameAndResourceIdFromRoute } from './lib/tracking/viewed-event-util'
import { router } from './router'
import { UpdateHandler } from './update'

export const App = memo(function App() {
  const sendMessageCallback = useCallback((context: ButtonClickContext) => {
    const matches = router.matchRoutes(window.location.pathname, window.location.search)
    const match = matches.at(-1)
    if (!match) return
    const resourceAndPath = getPageNameAndResourceIdFromRoute({ routeId: match.routeId, params: match.params })
    sendMessage({
      event: 'click_button',
      button_name: context.buttonName ?? null,
      button_icon: context.buttonIcon ?? null,
      button_text: context.buttonText ?? null,
      page_name: isSuccess(resourceAndPath) ? resourceAndPath.output.pageName : null,
      page_resource_id: isSuccess(resourceAndPath) ? resourceAndPath.output.resourceId : null,
    })
  }, [])

  return (
    <PharaohWrapper clickButtonCallback={sendMessageCallback}>
      <SessionProvider>
        <SessionLanguageProvider>
          <EmbeddingPartnerWrapper>
            <Suspense fallback={<GlobalLoadingState />}>
              <TemporaryNotificationAlertProvider>
                <ToastProvider>
                  {/* Catches expected failures stored in the global state and display a fallback screen */}
                  <UpdateHandler>
                    <RouterProvider router={router} />
                  </UpdateHandler>
                </ToastProvider>
              </TemporaryNotificationAlertProvider>
            </Suspense>
          </EmbeddingPartnerWrapper>
        </SessionLanguageProvider>
      </SessionProvider>
    </PharaohWrapper>
  )
})
