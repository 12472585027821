import { BooleanDimension } from '../boolean-dimension'

export const esRcphHeavySingleCustomerRelevantDimension = new BooleanDimension({
  name: 'esRcphHeavySingleCustomerRelevant',
  displayKeys: {
    name: 'dimension_name_es_rcph_heavy_single_customer_relevant',
  },
  tags: ['ES-RCPH'],
} as const)

export const esRcphHeavySingleCustomerDimension = new BooleanDimension({
  name: 'esRcphHeavySingleCustomer',
  displayKeys: {
    name: 'dimension_name_es_rcph_heavy_single_customer',
  },
  tags: ['ES-RCPH'],
} as const)
