import { Container } from '@mui/system'
import { memo } from 'react'
import { useTranslate } from '../../../localization'
import { ValidatedTextFieldFormField } from '../../molecules'

import { Disclaimer } from './common/GoCardlessDisclaimer'
import { IbanFormatter } from './common/iban-formatter'
import { SepaCustomerDataInputs } from './common/SepaCustomerDataInputs'
import { IBAN_MAPPER, IbanFormFields } from './common/utils'

type UpdatePaymentIbanWizardProps = {
  iban: string | undefined
  onIbanChange: (iban: string | undefined) => void
  updatedFirstName: string | undefined
  onFirstNameChange: (firstName: string | undefined) => void
  updatedLastName: string | undefined
  onLastNameChange: (lastName: string | undefined) => void
  updatedEmail: string | undefined
  onEmailChange: (email: string | undefined) => void
  updatedAddress: string | undefined
  onAddressChange: (address: string | undefined) => void
  updatedCity: string | undefined
  onCityChange: (city: string | undefined) => void
  updatedPostalCode: string | undefined
  onPostalCodeChange: (postalCode: string | undefined) => void
}

export const UpdatePaymentIbanWizard = memo<UpdatePaymentIbanWizardProps>(function UpdatePaymentIbanWizard({
  iban,
  onIbanChange,
  updatedFirstName,
  onFirstNameChange,
  updatedLastName,
  onLastNameChange,
  updatedEmail,
  onEmailChange,
  updatedAddress,
  onAddressChange,
  updatedCity,
  onCityChange,
  updatedPostalCode,
  onPostalCodeChange,
}) {
  const translate = useTranslate()

  const gocardlessConfidentialityLink = (
    <a href="https://gocardless.com/fr-fr/privacy/" target="_blank" rel="noopener noreferrer">
      {translate('gocardless_confidentiality_link_text')}
    </a>
  )

  return (
    <>
      <Container>
        <IbanFormFields>
          <ValidatedTextFieldFormField
            size="large"
            label="IBAN"
            value={iban}
            placeholder={translate('IBAN_placeholder')}
            onChange={onIbanChange}
            mapper={IBAN_MAPPER}
            slotProps={{
              input: {
                inputComponent: IbanFormatter,
              },
            }}
          />

          <SepaCustomerDataInputs
            onFirstNameChange={onFirstNameChange}
            firstNameValue={updatedFirstName ?? ''}
            onLastNameChange={onLastNameChange}
            lastNameValue={updatedLastName ?? ''}
            onAddressChange={onAddressChange}
            addressValue={updatedAddress ?? ''}
            onEmailChange={onEmailChange}
            emailValue={updatedEmail ?? ''}
            onPostalCodeChange={onPostalCodeChange}
            postalCodeValue={updatedPostalCode ?? ''}
            onCityChange={onCityChange}
            cityValue={updatedCity ?? ''}
          />
        </IbanFormFields>

        <Disclaimer gocardlessConfidentialityLink={gocardlessConfidentialityLink} />
      </Container>
    </>
  )
})
