import { TechnicalError } from '@orus.eu/error'
import { translateIn, type Language, type TranslateInSpecificLanguageFunction } from '@orus.eu/translations'
import { createContext, useContext } from 'react'

const languageContext = createContext<Language | undefined>(undefined)

/**
 * The language provider is used to set the language for the current component and its children.
 * If a language is set, it will always be used.
 * If undefined is passed, the default language will be used.
 */
export const LanguageProvider: React.Provider<Language | undefined> = languageContext.Provider

const defaultLanguageContext = createContext<
  { language: Language; setLanguage: (language: Language) => void } | undefined
>(undefined)

/**
 * Allows setting the default language dynamically. The language set is stored in a wrapper
 * at the root of the application, so that we don't have flashes of untranslated content during
 * loading phases.
 */
export function useSetDefaultLanguage(): (language: Language) => void {
  const defaultLanguageContextValue = useContext(defaultLanguageContext)
  if (!defaultLanguageContextValue) {
    throw new TechnicalError('defaultLanguageContext not found')
  }
  return defaultLanguageContextValue.setLanguage
}

export function useLanguage(): Language {
  const defaultLanguageContextValue = useContext(defaultLanguageContext)
  const languageContextValue = useContext(languageContext)
  if (!defaultLanguageContextValue) {
    throw new TechnicalError('defaultLanguageContext not found')
  }
  return languageContextValue ?? defaultLanguageContextValue.language
}

export const DefaultLanguageProvider: React.Provider<
  { language: Language; setLanguage: (language: Language) => void } | undefined
> = defaultLanguageContext.Provider

export function useTranslate(): TranslateInSpecificLanguageFunction {
  return translateIn[useLanguage()]
}
