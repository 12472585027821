import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { forwardRef, memo, useMemo } from 'react'
import { borderRadius, borderStroke, colorTokens, spacing } from '../../foundation'
import { Avatar, AvatarContext, Text, type CompoundIconName } from '../atoms'

export type OrusTabVariant = 'neutral' | 'info' | 'success' | 'warning' | 'danger'

export type OrusTabSize = 'small' | 'medium' | 'large'

export type TabProps = {
  children: React.ReactNode
  variant?: OrusTabVariant
  className?: string
  size?: OrusTabSize
  disabled?: boolean
  active?: boolean
  avatar?: React.ReactNode
  icon?: CompoundIconName
  badge?: React.ReactNode
  onClick?: () => void
}

export const Tab = memo(
  forwardRef<HTMLDivElement, TabProps>(function Checkbox(props, ref) {
    const {
      children,
      variant = 'neutral',
      size = 'small',
      disabled = false,
      active = false,
      onClick,
      avatar,
      icon,
      badge,
    } = props

    const setupAvatar = useMemo(() => {
      const avatarContext = { size: '10' } as const

      return avatar || icon ? (
        <AvatarContext.Provider value={avatarContext}>{avatar || <Avatar icon={icon} />}</AvatarContext.Provider>
      ) : (
        <AvatarContext.Provider value={avatarContext}>
          {avatarPropsPerTabVariant[variant] ? (
            <Avatar icon={avatarPropsPerTabVariant[variant].icon} color={avatarPropsPerTabVariant[variant].color} />
          ) : null}
        </AvatarContext.Provider>
      )
    }, [avatar, icon, variant])

    return (
      <StyledTabContainer
        role="tab"
        size={size}
        active={active}
        onClick={() => !disabled && onClick && onClick()}
        aria-selected={active}
        ref={ref}
      >
        <StyledTabContent
          size={size}
          disabled={disabled}
          tabIndex={active ? 0 : -1}
          tabStyle={tabStylePerVariant[variant]}
        >
          {setupAvatar}
          <Text variant="body2">{children}</Text>
          {badge}
        </StyledTabContent>
      </StyledTabContainer>
    )
  }),
)

const StyledTabContainer = styled.div<{ size: OrusTabSize; active: boolean }>`
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;

  padding-bottom: ${({ size }) => containerPaddingBottomPerSize[size]};

  min-width: ${spacing[70]};

  background-color: white;
`

const StyledTabContent = styled.button<{ size: OrusTabSize; disabled: boolean; tabStyle: OrusTabStyle }>`
  appearance: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: ${spacing[30]};
  border: none;
  outline: none;
  box-sizing: border-box;

  padding: ${({ size }) => contentPaddingPerSize[size]};
  border-radius: ${borderRadius[10]};

  color: ${({ tabStyle }) => tabStyle.color};
  background-color: ${({ tabStyle }) => tabStyle.backgroundColor};
  cursor: pointer;

  &:hover {
    ${({ tabStyle }) => (tabStyle.hoverBackgroundColor ? `background-color: ${tabStyle.hoverBackgroundColor}` : '')};
  }

  &:focus-visible {
    border: ${borderStroke[20]} solid ${colorTokens['color-stroke-base-focus']};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colorTokens['color-text-base-disable']};
      cursor: default;
      pointer-events: none;
    `}
`

const contentPaddingPerSize: Record<OrusTabSize, string> = {
  small: `${spacing[10]} ${spacing[30]}`,
  medium: `${spacing[20]} ${spacing[30]}`,
  large: `${spacing[30]} ${spacing[30]}`,
}

const containerPaddingBottomPerSize: Record<OrusTabSize, string> = {
  small: spacing[10],
  medium: spacing[20],
  large: spacing[20],
}

type OrusTabStyle = {
  backgroundColor: string
  hoverBackgroundColor?: string
  color: string
}

type TabStylePerVariant = Record<OrusTabVariant, OrusTabStyle>

const tabStylePerVariant: TabStylePerVariant = {
  neutral: {
    backgroundColor: 'transparent',
    hoverBackgroundColor: colorTokens['color-bg-base-hover'],
    color: colorTokens['color-text-base-main'],
  },
  info: {
    backgroundColor: colorTokens['color-bg-info-inverse'],
    color: colorTokens['color-text-info-primary'],
  },
  success: {
    backgroundColor: colorTokens['color-bg-success-inverse'],
    color: colorTokens['color-text-success-primary'],
  },
  warning: {
    backgroundColor: colorTokens['color-bg-warning-inverse'],
    color: colorTokens['color-text-warning-primary'],
  },
  danger: {
    backgroundColor: colorTokens['color-bg-danger-inverse'],
    color: colorTokens['color-text-danger-primary'],
  },
}

type AvatarPropsPerTabVariant = Record<
  OrusTabVariant,
  | undefined
  | {
      color: string
      icon: CompoundIconName
    }
>

const avatarPropsPerTabVariant: AvatarPropsPerTabVariant = {
  neutral: undefined,
  info: {
    color: colorTokens['color-fg-info'],
    icon: 'circle-info-solid',
  },
  success: {
    color: colorTokens['color-fg-success'],
    icon: 'circle-check-solid',
  },
  warning: {
    color: colorTokens['color-fg-warning'],
    icon: 'triangle-exclamation-solid',
  },
  danger: {
    color: colorTokens['color-fg-danger'],
    icon: 'diamond-exclamation-solid',
  },
}
