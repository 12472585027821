import { z } from 'zod'
import { StringsEnumDimension } from '../strings-enum-dimension'

/**
 * Hiscox ES products
 * Source : https://www.notion.so/orus-team/Flow-questions-18f920a02bab485fa0958e4b656260dc
 */
export const esRcphProducts = [
  'MAC',
  'MAN',
  'MEDIA',
  'MISC',
  'TECH', // TECNOLOGÍA
  'GEST', // Asesorías/gestorías
  'ECO', // Industria: Actividades Relacionadas con la Construccion (ECO - Construction)
  'ENO', // Actividades No Relacionadas con Construcción (ENO - Non construction)
  'ETG', // Transporte de Mercancias / grandes dimensiones (No peligrosas) - (ETG - Transport of non dangerous goods)
] as const

export type EsRcphProductCategory = 'es-rcph-pro' | 'es-rcph-general'

export const esRcphProductCategory: Record<EsRcphProduct, EsRcphProductCategory> = {
  MAC: 'es-rcph-pro',
  MAN: 'es-rcph-pro',
  MEDIA: 'es-rcph-pro',
  MISC: 'es-rcph-pro',
  TECH: 'es-rcph-pro',
  GEST: 'es-rcph-pro',
  ECO: 'es-rcph-general',
  ENO: 'es-rcph-general',
  ETG: 'es-rcph-general',
}

export const EsRcphProductSchema = z.enum(esRcphProducts)

export type EsRcphProduct = z.infer<typeof EsRcphProductSchema>

export const esRcphProductDimension = new StringsEnumDimension({
  name: 'esRcphProduct',
  displayKeys: {
    name: 'dimension_name_es_rcph_product',
  },
  values: esRcphProducts,
  tags: ['ES-RCPH'],
} as const)
