import { OptionsListDimension } from '../options-list-dimension.js'

export const esRcphOptionTypeNames = [
  'PURE_FINANCIAL_LOSS',
  'CROSS_LIABILITY',
  'US_CANADA',
  'GL',
  'FREEDOM_ADVANTAGE',
] as const
export type EsRcphOptionType = (typeof esRcphOptionTypeNames)[number]

export const esRcphOptionsDimension = new OptionsListDimension<'esRcphOptions', EsRcphOptionType>({
  name: 'esRcphOptions',
  displayValues: { name: 'Active options' },
  tags: ['ES-RCPH'],
} as const)
