import { TechnicalError } from '@orus.eu/error'
import { createContext, useContext } from 'react'

const chatContext = createContext<() => void>(() => {
  throw new TechnicalError('openChat not initialized')
})

export function useOpenChat(): () => void {
  return useContext(chatContext)
}

export const ChatProvider: React.Provider<() => void> = chatContext.Provider
