import styled from '@emotion/styled'
import { memo, type ReactNode } from 'react'

import type { Color } from '../../colors'
import { Text, TextContainer } from '../../components'
import { cssPropsPerScreenVariantPerTextVariant } from '../../components/atoms/text'
import { colorTokens, spacing } from '../../foundation'
import { useUiContext } from '../../hooks'

export type CardProps = {
  children: ReactNode
  title: string
  subtitle: string
  subtitleMedium?: string
  avatar: React.ReactNode
  backgroundColor?: Color
  withBorder?: boolean
  fullwidth?: boolean
  titleColor?: Color
  subtitleColor?: Color
  bodyMarginBottom?: string
}

export const Card = memo<CardProps>(function Card(props: CardProps) {
  const screenVariant = useUiContext()
  const subtitleMediumCssProps = cssPropsPerScreenVariantPerTextVariant['body2Medium'][screenVariant]

  const {
    children,
    title,
    subtitle,
    subtitleMedium,
    avatar,
    backgroundColor,
    titleColor,
    subtitleColor,
    withBorder = false,
    fullwidth = false,
    bodyMarginBottom = spacing['90'],
  } = props

  return (
    <CardContainer backgroundColor={backgroundColor} withBorder={withBorder} fullwidth={fullwidth}>
      {avatar}
      <CardBody bodyMarginBottom={bodyMarginBottom}>
        <Text variant="subtitle1" color={titleColor}>
          {title}
        </Text>
        <Text variant="body2" color={subtitleColor}>
          {subtitle}
          {subtitleMedium ? <span style={subtitleMediumCssProps}>{subtitleMedium}</span> : undefined}
        </Text>
      </CardBody>
      <CardFooter>{children}</CardFooter>
    </CardContainer>
  )
})

const CardContainer = styled.div<{ backgroundColor?: Color; withBorder: boolean; fullwidth: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${spacing['70']};
  border-radius: ${spacing['40']};
  border: ${(props) =>
    `var(--border-stroke-20, 1px) solid ${props.withBorder ? colorTokens['color-stroke-base'] : 'transparent'}`};
  background: ${(props) => props.backgroundColor ?? colorTokens['color-bg-base-normal']};
  width: ${(props) => (props.fullwidth ? '100%' : '320px')};
`

const CardBody = styled(TextContainer)<{ bodyMarginBottom: string }>`
  margin-top: ${spacing['40']};
  margin-bottom: ${(props) => props.bodyMarginBottom};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: ${spacing['30']};
`

const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing['40']};
`
