import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { createLink } from '@tanstack/react-router'
import { forwardRef, memo } from 'react'
import { Icon, Tooltip, type CompoundIconName } from '../../components'
import { borderRadius, borderStroke, colorTokens } from '../../foundation'

export type NavbarButtonVariant = 'basic' | 'profil'

type NavbarButtonState = 'enable' | 'hover' | 'focus' | 'active' | 'activeFocus' | 'disabled'

type StyledButtonProps = {
  buttonStylePerState: ButtonStylesPerState
  isActive: boolean
}

type ButtonStylesPerState = Record<NavbarButtonState, { backgroundColor: string; border: string }>

export type NavbarButtonProps = {
  variant: NavbarButtonVariant
  icon: CompoundIconName
  title?: string
  href?: string
  ariaLabel?: string
  onClick?: () => void
  forceActive?: boolean
  forceInactive?: boolean
  className?: string
  disabled?: boolean

  'data-testid'?: string
  'data-status'?: 'active' | undefined
}

export const NavbarButton = memo(
  forwardRef<HTMLButtonElement, NavbarButtonProps>(function NavbarButton(props, ref) {
    const {
      icon,
      ariaLabel,
      title,
      'data-testid': dataTestId,
      onClick,
      forceActive,
      forceInactive,
      disabled = false,
      ...otherProps
    } = props

    const isActive = forceActive ? true : forceInactive ? false : otherProps['data-status'] === 'active'

    const button = (
      <StyledButton
        ref={ref}
        as={props.href ? 'a' : 'button'}
        isActive={isActive}
        aria-label={ariaLabel ?? title}
        data-testid={dataTestId}
        buttonStylePerState={buttonStylePerState}
        disabled={disabled}
        onClick={onClick}
        {...otherProps}
      >
        <Icon icon={icon} size="30" />
      </StyledButton>
    )

    if (!title) return button

    return (
      <Tooltip title={title} showArrow={false} arrowVerticalPosition="middle" arrowHorizontalPosition="left">
        {button}
      </Tooltip>
    )
  }),
)

export const NavbarButtonLink = createLink(NavbarButton)

const prosThatShouldNotBeForwarded = new Set(['buttonStylePerState', 'isActive'])

const StyledButton = styled('button', {
  shouldForwardProp: (propName: string) => !prosThatShouldNotBeForwarded.has(propName) && isPropValid(propName),
})<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  outline: none;
  text-decoration: none;
  border: none;
  cursor: pointer;

  border-radius: ${borderRadius['20']};

  width: 32px !important;
  height: 32px !important;
  box-sizing: border-box;

  transition: background-color 0.25s ease-in-out;
  color: ${colorTokens['color-fg-base-tertiary']};

  ${({ buttonStylePerState }) => buttonStylePerState.enable}

  &:hover {
    ${({ buttonStylePerState }) => buttonStylePerState.hover}
  }

  &:focus-visible {
    ${({ buttonStylePerState }) => buttonStylePerState.focus}
  }

  &:active {
    ${({ buttonStylePerState }) => buttonStylePerState.active}
  }

  ${({ isActive }) => (isActive ? buttonStylePerState.active : null)}

  &:disabled {
    pointer-events: none;
    box-shadow: none !important;
    color: ${colorTokens['color-fg-base-disable']};
    ${({ buttonStylePerState }) => buttonStylePerState.disabled};
  }
`

const buttonStylePerState: ButtonStylesPerState = {
  enable: { backgroundColor: 'transparent', border: 'none' },
  hover: { backgroundColor: colorTokens['color-bg-base-tertiary'], border: 'none' },
  focus: {
    backgroundColor: colorTokens['color-bg-base-tertiary'],
    border: `${borderStroke[20]} solid ${colorTokens['color-stroke-base-focus']}`,
  },
  active: {
    backgroundColor: colorTokens['color-bg-base-tertiary-active'],
    border: 'none',
  },
  activeFocus: {
    backgroundColor: colorTokens['color-bg-base-tertiary-active'],
    border: `${borderStroke[20]} solid ${colorTokens['color-stroke-base-focus']}`,
  },
  disabled: { backgroundColor: 'transparent', border: 'none' },
}
