import { assert, TechnicalError } from '@orus.eu/error'
import { isPharaohSkin, useCrash, usePharaohSkin, useSetSkin, type Skin } from '@orus.eu/pharaoh'
import { memo, useEffect, useMemo, useState, type ReactNode } from 'react'
import { trpcReact } from '../../client'
import { EmbeddingPartnerProvider } from '../../lib/embedding-partner'
import { useSession } from '../../lib/session'

export const EmbeddingPartnerWrapper = memo<{ children: ReactNode }>(function EmbeddingPartnerWrapper({ children }) {
  const session = useSession()

  const crash = useCrash()
  const [embeddingPartnerId, setEmbeddingPartnerId] = useState<string | undefined>(getInitialEmbeddingPartnerId())
  const setSkin = useSetSkin()
  const { setSkin: setPharaohSkin } = usePharaohSkin()

  const hasPermissions =
    session?.permissions.type === 'client' &&
    session.permissions.rolesPermissions.flatMap((r) => r.permissions).includes('contracts.read')

  const {
    data: contracts,
    isLoading,
    error,
  } = trpcReact.contracts.listMyContracts.useQuery(undefined, {
    enabled: hasPermissions,
  })

  // This effects reacts to session changes to check if an embedding partner needs
  // to be applied, based on the customer's contract
  useEffect(() => {
    if (!hasPermissions || isLoading) return

    if (error) {
      crash(new TechnicalError('Error while loading contracts', { context: { sessionId: session.id } }))
      return
    }

    assert(contracts)

    if (contracts.length < 1) {
      // No contract => no embedding partner
      setEmbeddingPartnerId(undefined)
      return
    }

    // Use embedding partner of the first contract
    const firstContract = contracts[0]
    const embeddingPartner = firstContract.embeddingPartner
    setEmbeddingPartnerId(embeddingPartner === 'none' ? undefined : embeddingPartner.id)
  }, [contracts, session, setEmbeddingPartnerId, isLoading, crash, hasPermissions, error])

  // This effect reacts to embeddingPartner changes to update the skin accordingly
  useEffect(() => {
    if (!embeddingPartnerId) {
      setSkin(null)
      setPharaohSkin('light')
      return
    }

    if (isPharaohSkin(embeddingPartnerId)) setPharaohSkin(embeddingPartnerId)

    let cancelled = false

    skinFactories[embeddingPartnerId]()
      .then((skin) => {
        if (cancelled) {
          return
        }

        setSkin(skin)
      })
      .catch((err) => {
        if (cancelled) {
          return
        }

        console.error(err)
      })

    return () => {
      cancelled = true
    }
  }, [embeddingPartnerId, setSkin, setPharaohSkin])

  const embeddingPartnerContextValue = useMemo(
    () => ({ embeddingPartnerId, setEmbeddingPartnerId }),
    [embeddingPartnerId, setEmbeddingPartnerId],
  )

  return <EmbeddingPartnerProvider value={embeddingPartnerContextValue}>{children}</EmbeddingPartnerProvider>
})

function getInitialEmbeddingPartnerId(): string | undefined {
  const url = new URL(document.location.href)

  const embeddingPartnerParameter = url.searchParams.get('embeddingPartner')
  if (embeddingPartnerParameter && embeddingPartnerParameter in skinFactories) {
    return embeddingPartnerParameter
  }

  const organizationParameter = url.searchParams.get('organization')
  if (organizationParameter && organizationParameter in skinFactories) {
    return organizationParameter
  }

  return undefined
}

const skinFactories: Record<string, () => Promise<Skin>> = {
  sidecare: () => import('../../lib/skins/sidecare').then((m) => m.sidecareSkin),
  superindep: () => import('../../lib/skins/superindep').then((m) => m.superindepSkin),
  propulse: () => import('../../lib/skins/propulse').then((m) => m.propulseSkin),
  jump: () => import('../../lib/skins/jump').then((m) => m.jumpSkin),
  shine: () => import('../../lib/skins/shine').then((m) => m.shineSkin),
  legalstart: () => import('../../lib/skins/legalstart').then((m) => m.legalstartSkin),
  dougs: () => import('../../lib/skins/dougs').then((m) => m.dougsSkin),
  previi: () => import('../../lib/skins/previi').then((m) => m.previiSkin),
}
