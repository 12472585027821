import isPropValid from '@emotion/is-prop-valid'
import { css, type SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { createLink } from '@tanstack/react-router'
import { forwardRef, memo, useCallback, useContext, useMemo, type MouseEvent, type MouseEventHandler } from 'react'
import { colorTokens } from '../../foundation/color-tokens.js'
import { spacing } from '../../foundation/index.js'
import { shadow } from '../../foundation/shadow-tokens.js'
import { useUiContext, type UiContext } from '../../hooks/use-screen-variant.js'
import { TrackButtonClickContext } from '../../wrapper-context.js'
import { Avatar } from '../atoms/avatar/avatar.js'
import { AvatarContext } from '../atoms/avatar/context.js'
import type { CompoundIconName, IconSize } from '../atoms/icon/index.js'
import type { TextVariant } from '../atoms/text/index.js'
import { Text } from '../atoms/text/text.js'
import { Spinner } from '../spinner/index.js'
import { Tooltip } from '../tooltip/tooltip.js'
import { buttonDimensionsPerButtonSize, type ButtonSize } from './sizes.js'

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'text'
export type ButtonStyle = 'base' | 'danger' | 'success' | 'warning' | 'decorative' | 'info'
export type ButtonAvatarPlacement = 'left' | 'right'
type ButtonTheme = {
  [style in ButtonStyle]: {
    [state in ButtonState]: { backgroundColor: string; color: string; iconColor: string; borderColor?: string }
  }
}

export type ButtonProps = {
  /** The button size. Not applicable to variant `'text'`. Defaults to `'medium'` */
  size?: ButtonSize
  /** The button variant. Defaults to `'primary'` */
  variant?: ButtonVariant
  /** The button text variant. Defaults to `'button'` */
  textVariant?: TextVariant
  /** The button style. Defaults to `'base'` */
  style?: ButtonStyle
  /** Whether the button should take the full width. Defaults to `false` */
  fullWidth?: boolean
  /** The avatar to show */
  avatar?: React.ReactNode
  /** The icon to create an avatar from when no avatar is otherwise provided. Ignored if an avatar is provided. */
  icon?: CompoundIconName
  /** The position of the avatar. Only applicable if `avatar` is set. Defaults to `'right'` */
  avatarPosition?: ButtonAvatarPlacement

  /** Whether the button is disabled. Defaults to `false` */
  disabled?: boolean
  /** The button type. Defaults to `button` */
  type?: 'button' | 'submit' | 'reset'
  /** The button data-testid */
  dataTestId?: string

  onClick?: MouseEventHandler<HTMLButtonElement>

  children?: React.ReactNode
  className?: string
  ariaLabel?: string
  title?: string
  isLoading?: boolean
  trackingId?: string
  href?: string
  avatarSize?: IconSize
  /**
   * Optional id of the form the button belongs to
   */
  form?: string
  screenVariant?: UiContext
}

export const Button = memo(
  forwardRef<HTMLButtonElement, ButtonProps>(function Button(props, ref) {
    const {
      size = 'medium',
      variant = 'primary',
      textVariant = 'button',
      style = 'base',
      fullWidth = false,
      avatar,
      icon,
      avatarPosition = 'right',
      disabled = false,
      type = 'button',
      onClick,
      children,
      className,
      ariaLabel,
      title,
      isLoading,
      trackingId,
      form,
      dataTestId,
      screenVariant,
      avatarSize,
      ...linkProps
    } = props

    const avatarContext = useMemo(() => ({ size: avatarSize ?? '30' }) as const, [avatarSize])

    const localScreenVariant = useUiContext()

    const iconOnly = !children
    const setupAvatar = useMemo(
      () =>
        avatar || icon ? (
          <AvatarContext.Provider value={avatarContext}>
            <IconWrapper>{avatar || <Avatar icon={icon} />}</IconWrapper>
          </AvatarContext.Provider>
        ) : (
          <></>
        ),
      [avatar, avatarContext, icon],
    )

    const trackButtonContext = useContext(TrackButtonClickContext)

    const handleClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        trackButtonContext({
          buttonName: trackingId,
          buttonText: event.currentTarget.textContent || undefined,
          buttonIcon: icon,
        })
        onClick?.(event)
      },
      [icon, onClick, trackButtonContext, trackingId],
    )

    const button = (
      <StyledButton
        ref={ref}
        as={!disabled && 'href' in props ? 'a' : 'button'}
        size={size}
        variant={variant}
        buttonStyle={style}
        fullWidth={fullWidth}
        avatarPosition={avatarPosition}
        iconOnly={iconOnly}
        disabled={disabled}
        type={isLoading ? 'button' : type}
        className={className}
        onClick={isLoading || ('href' in props && 'onClick' in props) ? onClick : handleClick}
        aria-label={ariaLabel}
        form={form}
        data-testid={dataTestId}
        {...linkProps}
      >
        {children ? (
          <div
            css={css`
              flex-direction: column;
              display: flex;
              align-items: center;
              position: relative;
            `}
          >
            {isLoading ? (
              <div
                css={css`
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                `}
              >
                <Spinner strokeColor={getSpinnerColor(disabled, variant, style)} size="40" />
              </div>
            ) : undefined}
            <Text
              screenVariant={screenVariant ?? localScreenVariant}
              variant={textVariant}
              css={css`
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                visibility: ${isLoading ? 'hidden' : 'visible'};
              `}
            >
              {children}
            </Text>
          </div>
        ) : (
          <></>
        )}
        {setupAvatar}
      </StyledButton>
    )

    if (!title) return button

    return <Tooltip title={title}>{button}</Tooltip>
  }),
)

export const ButtonLink = createLink(Button)

const prosThatShouldNotBeForwarded = new Set([
  'buttonStyle',
  'iconOnly',
  'size',
  'variant',
  'theme',
  'style',
  'fullWidth',
  'avatar',
  'icon',
  'avatarPosition',
  'title',
  'isLoading',
  'trackingId',
])

const buttonShouldForwardProp = (propName: string) =>
  !prosThatShouldNotBeForwarded.has(propName) && isPropValid(propName)

type StyledButtonProps = Required<Pick<ButtonProps, 'size' | 'variant' | 'fullWidth' | 'avatarPosition'>> & {
  buttonStyle: ButtonStyle
  iconOnly: boolean
  to?: string
  target?: string
}

const StyledButton = styled('button', {
  shouldForwardProp: buttonShouldForwardProp,
})<StyledButtonProps>`
  flex-shrink: 0;
  outline: none;

  display: ${({ fullWidth }) => (fullWidth ? 'flex' : 'inline-flex')};
  flex-direction: ${({ avatarPosition }) => (avatarPosition === 'left' ? 'row-reverse' : 'row')};
  text-decoration: none;
  justify-content: center;
  align-items: center;

  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  border: none;
  cursor: pointer;

  transition: all 0.25s ease-in-out;

  &:disabled {
    pointer-events: none;
    box-shadow: none !important;
  }

  ${({ variant }) => buttonStylePerVariant[variant]}
`

const sizedStyle = (props: StyledButtonProps) => css`
  gap: ${spacing[30]};

  height: ${buttonDimensionsPerButtonSize[props.size].height};
  ${props.iconOnly
    ? `width: ${buttonDimensionsPerButtonSize[props.size].height}; padding: ${buttonDimensionsPerButtonSize[props.size].iconPadding};`
    : `padding: ${buttonDimensionsPerButtonSize[props.size].padding};`};

  border-radius: 8px;
`

const primaryStyle = (props: StyledButtonProps) => css`
  ${sizedStyle(props)}

  box-shadow: ${shadow.bottom['05']};

  background-color: ${primaryColorsPerButtonStylePerTheme[props.buttonStyle].idle.backgroundColor};
  color: ${primaryColorsPerButtonStylePerTheme[props.buttonStyle].idle.color};

  --icon-color: ${primaryColorsPerButtonStylePerTheme[props.buttonStyle].idle.iconColor};

  &:hover {
    background-color: ${primaryColorsPerButtonStylePerTheme[props.buttonStyle].hover.backgroundColor};
    color: ${primaryColorsPerButtonStylePerTheme[props.buttonStyle].hover.color};
    box-shadow: ${props.buttonStyle !== 'decorative' ? shadow.bottom['05'] : 0};

    --icon-color: ${primaryColorsPerButtonStylePerTheme[props.buttonStyle].hover.iconColor};
  }

  &:active,
  &:focus {
    background-color: ${primaryColorsPerButtonStylePerTheme[props.buttonStyle].active.backgroundColor};
    color: ${primaryColorsPerButtonStylePerTheme[props.buttonStyle].active.color};
    box-shadow: ${props.buttonStyle !== 'decorative' ? shadow.bottom['05'] : 0};

    --icon-color: ${primaryColorsPerButtonStylePerTheme[props.buttonStyle].active.iconColor};
  }

  &:disabled {
    background-color: ${primaryColorsPerButtonStylePerTheme[props.buttonStyle].disabled.backgroundColor};
    color: ${primaryColorsPerButtonStylePerTheme[props.buttonStyle].disabled.color};
    box-shadow: ${props.buttonStyle !== 'decorative' ? shadow.bottom['05'] : 0};

    --icon-color: ${primaryColorsPerButtonStylePerTheme[props.buttonStyle].disabled.iconColor};
  }
`

type ButtonState = 'idle' | 'hover' | 'active' | 'disabled'

const secondaryStyle = (props: StyledButtonProps) => css`
  ${sizedStyle(props)}

  box-shadow: ${shadow.bottom['05']};

  background-color: ${secondaryColorsPerButtonStylePerTheme[props.buttonStyle].idle.backgroundColor};
  color: ${secondaryColorsPerButtonStylePerTheme[props.buttonStyle].idle.color};
  border: 1px solid ${secondaryColorsPerButtonStylePerTheme[props.buttonStyle].idle.borderColor};

  --icon-color: ${secondaryColorsPerButtonStylePerTheme[props.buttonStyle].idle.iconColor};

  &:hover {
    background-color: ${secondaryColorsPerButtonStylePerTheme[props.buttonStyle].hover.backgroundColor};
    color: ${secondaryColorsPerButtonStylePerTheme[props.buttonStyle].hover.color};
    border-color: ${secondaryColorsPerButtonStylePerTheme[props.buttonStyle].hover.borderColor};
    box-shadow: ${props.buttonStyle !== 'decorative' ? shadow.bottom['05'] : 0};

    --icon-color: ${secondaryColorsPerButtonStylePerTheme[props.buttonStyle].hover.iconColor};
  }

  &:active,
  &:focus {
    background-color: ${secondaryColorsPerButtonStylePerTheme[props.buttonStyle].active.backgroundColor};
    color: ${secondaryColorsPerButtonStylePerTheme[props.buttonStyle].active.color};
    border-color: ${secondaryColorsPerButtonStylePerTheme[props.buttonStyle].active.borderColor};
    box-shadow: ${props.buttonStyle !== 'decorative' ? shadow.bottom['05'] : 0};

    --icon-color: ${secondaryColorsPerButtonStylePerTheme[props.buttonStyle].active.iconColor};
  }

  &:disabled {
    background-color: ${secondaryColorsPerButtonStylePerTheme[props.buttonStyle].disabled.backgroundColor};
    color: ${secondaryColorsPerButtonStylePerTheme[props.buttonStyle].disabled.color};
    border-color: ${secondaryColorsPerButtonStylePerTheme[props.buttonStyle].disabled.borderColor};
    box-shadow: ${props.buttonStyle !== 'decorative' ? shadow.bottom['05'] : 0};

    --icon-color: ${secondaryColorsPerButtonStylePerTheme[props.buttonStyle].disabled.iconColor};
  }
`

const tertiaryStyle = (props: StyledButtonProps) => css`
  ${sizedStyle(props)}

  background-color: ${tertiaryColorsPerButtonStylePerTheme[props.buttonStyle].idle.backgroundColor};
  color: ${tertiaryColorsPerButtonStylePerTheme[props.buttonStyle].idle.color};

  --icon-color: ${tertiaryColorsPerButtonStylePerTheme[props.buttonStyle].idle.iconColor};

  &:hover {
    background-color: ${tertiaryColorsPerButtonStylePerTheme[props.buttonStyle].hover.backgroundColor};
    color: ${tertiaryColorsPerButtonStylePerTheme[props.buttonStyle].hover.color};

    --icon-color: ${tertiaryColorsPerButtonStylePerTheme[props.buttonStyle].hover.iconColor};
  }

  &:active,
  &:focus {
    background-color: ${tertiaryColorsPerButtonStylePerTheme[props.buttonStyle].active.backgroundColor};
    color: ${tertiaryColorsPerButtonStylePerTheme[props.buttonStyle].active.color};

    --icon-color: ${tertiaryColorsPerButtonStylePerTheme[props.buttonStyle].active.iconColor};
  }

  &:disabled {
    background-color: ${tertiaryColorsPerButtonStylePerTheme[props.buttonStyle].disabled.backgroundColor};
    color: ${tertiaryColorsPerButtonStylePerTheme[props.buttonStyle].disabled.color};

    --icon-color: ${tertiaryColorsPerButtonStylePerTheme[props.buttonStyle].disabled.iconColor};
  }
`

const textStyle = (props: StyledButtonProps) => css`
  gap: ${spacing[30]};

  padding: ${spacing[20]} 0;

  background-color: transparent;
  color: ${textColorsPerButtonStylePerTheme[props.buttonStyle].idle.color};

  --icon-color: ${textColorsPerButtonStylePerTheme[props.buttonStyle].idle.iconColor};

  &:hover {
    color: ${textColorsPerButtonStylePerTheme[props.buttonStyle].hover.color};

    --icon-color: ${textColorsPerButtonStylePerTheme[props.buttonStyle].hover.iconColor};
  }

  &:active,
  &:focus {
    color: ${textColorsPerButtonStylePerTheme[props.buttonStyle].active.color};

    --icon-color: ${textColorsPerButtonStylePerTheme[props.buttonStyle].active.iconColor};
  }

  &:disabled {
    color: ${textColorsPerButtonStylePerTheme[props.buttonStyle].disabled.color};

    --icon-color: ${textColorsPerButtonStylePerTheme[props.buttonStyle].disabled.iconColor};
  }

  text-decoration: underline;
`

const IconWrapper = styled.div`
  color: var(--icon-color);
`

const buttonStylePerVariant: {
  [variant in ButtonVariant]: (props: StyledButtonProps) => SerializedStyles
} = {
  primary: primaryStyle,
  secondary: secondaryStyle,
  tertiary: tertiaryStyle,
  text: textStyle,
}

const primaryColorsPerButtonStylePerTheme: ButtonTheme = {
  base: {
    idle: {
      backgroundColor: colorTokens['color-bg-base-primary'],
      color: colorTokens['color-text-base-primary'],
      iconColor: colorTokens['color-fg-base-primary'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-base-primary-hover'],
      color: colorTokens['color-text-base-primary'],
      iconColor: colorTokens['color-fg-base-primary'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-base-primary-active'],
      color: colorTokens['color-text-base-primary'],
      iconColor: colorTokens['color-fg-base-primary'],
    },
    disabled: {
      backgroundColor: colorTokens['color-bg-base-disable'],
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
    },
  },
  danger: {
    idle: {
      backgroundColor: colorTokens['color-bg-danger-primary'],
      color: colorTokens['color-text-base-primary'],
      iconColor: colorTokens['color-fg-base-primary'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-danger-primary-hover'],
      color: colorTokens['color-text-base-primary'],
      iconColor: colorTokens['color-fg-base-primary'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-danger-primary-active'],
      color: colorTokens['color-text-base-primary'],
      iconColor: colorTokens['color-fg-base-primary'],
    },
    disabled: {
      backgroundColor: colorTokens['color-bg-base-disable'],
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
    },
  },
  success: {
    idle: {
      backgroundColor: colorTokens['color-bg-success-primary'],
      color: colorTokens['color-text-base-primary'],
      iconColor: colorTokens['color-fg-base-primary'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-success-primary-hover'],
      color: colorTokens['color-text-base-primary'],
      iconColor: colorTokens['color-fg-base-primary'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-success-primary-active'],
      color: colorTokens['color-text-base-primary'],
      iconColor: colorTokens['color-fg-base-primary'],
    },
    disabled: {
      backgroundColor: colorTokens['color-bg-base-disable'],
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
    },
  },
  warning: {
    idle: {
      backgroundColor: colorTokens['color-bg-warning-primary'],
      color: colorTokens['color-text-base-primary'],
      iconColor: colorTokens['color-fg-base-primary'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-warning-primary-hover'],
      color: colorTokens['color-text-base-primary'],
      iconColor: colorTokens['color-fg-base-primary'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-warning-primary-active'],
      color: colorTokens['color-text-base-primary'],
      iconColor: colorTokens['color-fg-base-primary'],
    },
    disabled: {
      backgroundColor: colorTokens['color-bg-base-disable'],
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
    },
  },
  decorative: {
    idle: {
      backgroundColor: colorTokens['color-bg-decorative-primary'],
      color: colorTokens['color-text-decorative-primary'],
      iconColor: colorTokens['color-fg-decorative-primary'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-decorative-primary-hover'],
      color: colorTokens['color-text-decorative-primary'],
      iconColor: colorTokens['color-fg-decorative-primary'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-decorative-primary-active'],
      color: colorTokens['color-text-decorative-primary'],
      iconColor: colorTokens['color-fg-decorative-primary'],
    },
    disabled: {
      backgroundColor: colorTokens['color-bg-base-disable'],
      color: colorTokens['color-text-decorative-disable'],
      iconColor: colorTokens['color-fg-decorative-disable'],
    },
  },
  info: {
    idle: {
      backgroundColor: colorTokens['color-bg-info-primary'],
      color: colorTokens['color-text-base-primary'],
      iconColor: colorTokens['color-fg-base-primary'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-info-primary-hover'],
      color: colorTokens['color-text-base-primary'],
      iconColor: colorTokens['color-fg-base-primary'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-info-primary-active'],
      color: colorTokens['color-text-base-primary'],
      iconColor: colorTokens['color-fg-base-primary'],
    },
    disabled: {
      backgroundColor: colorTokens['color-bg-base-disable'],
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
    },
  },
}

const secondaryColorsPerButtonStylePerTheme: ButtonTheme = {
  base: {
    idle: {
      backgroundColor: colorTokens['color-bg-base-secondary'],
      color: colorTokens['color-text-base-secondary'],
      iconColor: colorTokens['color-fg-base'],
      borderColor: colorTokens['color-stroke-base'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-base-secondary-hover'],
      color: colorTokens['color-text-base-secondary'],
      iconColor: colorTokens['color-fg-base'],
      borderColor: colorTokens['color-stroke-base-hover'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-base-secondary-active'],
      color: colorTokens['color-text-base-secondary'],
      iconColor: colorTokens['color-fg-base'],
      borderColor: colorTokens['color-stroke-base-active'],
    },
    disabled: {
      backgroundColor: colorTokens['color-bg-base-disable'],
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
      borderColor: colorTokens['color-stroke-base-disable'],
    },
  },
  danger: {
    idle: {
      backgroundColor: colorTokens['color-bg-danger-secondary'],
      color: colorTokens['color-text-danger-secondary'],
      iconColor: colorTokens['color-fg-danger'],
      borderColor: colorTokens['color-stroke-danger'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-danger-secondary-hover'],
      color: colorTokens['color-text-danger-secondary'],
      iconColor: colorTokens['color-fg-danger'],
      borderColor: colorTokens['color-stroke-danger-hover'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-danger-secondary-active'],
      color: colorTokens['color-text-danger-secondary'],
      iconColor: colorTokens['color-fg-danger'],
      borderColor: colorTokens['color-stroke-danger-active'],
    },
    disabled: {
      backgroundColor: colorTokens['color-bg-base-disable'],
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
      borderColor: colorTokens['color-stroke-base-disable'],
    },
  },
  success: {
    idle: {
      backgroundColor: colorTokens['color-bg-success-secondary'],
      color: colorTokens['color-text-success-secondary'],
      iconColor: colorTokens['color-fg-success'],
      borderColor: colorTokens['color-stroke-success'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-success-secondary-hover'],
      color: colorTokens['color-text-success-secondary'],
      iconColor: colorTokens['color-fg-success'],
      borderColor: colorTokens['color-stroke-success-hover'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-success-secondary-active'],
      color: colorTokens['color-text-success-secondary'],
      iconColor: colorTokens['color-fg-success'],
      borderColor: colorTokens['color-stroke-success-active'],
    },
    disabled: {
      backgroundColor: colorTokens['color-bg-base-disable'],
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
      borderColor: colorTokens['color-stroke-base-disable'],
    },
  },
  warning: {
    idle: {
      backgroundColor: colorTokens['color-bg-warning-secondary'],
      color: colorTokens['color-text-warning-secondary'],
      iconColor: colorTokens['color-fg-warning'],
      borderColor: colorTokens['color-stroke-warning'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-warning-secondary-hover'],
      color: colorTokens['color-text-warning-secondary'],
      iconColor: colorTokens['color-fg-warning'],
      borderColor: colorTokens['color-stroke-warning-hover'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-warning-secondary-active'],
      color: colorTokens['color-text-warning-secondary'],
      iconColor: colorTokens['color-fg-warning'],
      borderColor: colorTokens['color-stroke-warning-active'],
    },
    disabled: {
      backgroundColor: colorTokens['color-bg-base-disable'],
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
      borderColor: colorTokens['color-stroke-base-disable'],
    },
  },
  decorative: {
    idle: {
      backgroundColor: colorTokens['color-bg-decorative-secondary'],
      color: colorTokens['color-text-decorative-secondary'],
      iconColor: colorTokens['color-fg-decorative-secondary'],
      borderColor: colorTokens['color-stroke-decorative'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-decorative-hover'],
      color: colorTokens['color-text-decorative-secondary'],
      iconColor: colorTokens['color-fg-decorative-secondary'],
      borderColor: colorTokens['color-stroke-decorative-hover'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-decorative-active'],
      color: colorTokens['color-text-decorative-secondary'],
      iconColor: colorTokens['color-fg-decorative-secondary'],
      borderColor: colorTokens['color-stroke-decorative-active'],
    },
    disabled: {
      backgroundColor: colorTokens['color-bg-base-disable'],
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-decorative-disable'],
      borderColor: colorTokens['color-stroke-base-disable'],
    },
  },
  info: {
    idle: {
      backgroundColor: colorTokens['color-bg-info-secondary'],
      color: colorTokens['color-text-info-secondary'],
      iconColor: colorTokens['color-fg-info'],
      borderColor: colorTokens['color-stroke-info'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-info-secondary-hover'],
      color: colorTokens['color-text-info-secondary'],
      iconColor: colorTokens['color-fg-info'],
      borderColor: colorTokens['color-stroke-info-hover'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-info-secondary-active'],
      color: colorTokens['color-text-info-secondary'],
      iconColor: colorTokens['color-fg-info'],
      borderColor: colorTokens['color-stroke-info-active'],
    },
    disabled: {
      backgroundColor: colorTokens['color-bg-base-disable'],
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
      borderColor: colorTokens['color-stroke-base-disable'],
    },
  },
}

const tertiaryColorsPerButtonStylePerTheme: ButtonTheme = {
  base: {
    idle: {
      backgroundColor: colorTokens['color-bg-base-tertiary'],
      color: colorTokens['color-text-base-tertiary'],
      iconColor: colorTokens['color-fg-base-tertiary'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-base-tertiary-hover'],
      color: colorTokens['color-text-base-tertiary'],
      iconColor: colorTokens['color-fg-base-tertiary'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-base-tertiary-active'],
      color: colorTokens['color-text-base-tertiary'],
      iconColor: colorTokens['color-fg-base-tertiary'],
    },
    disabled: {
      backgroundColor: 'transparent',
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
    },
  },
  danger: {
    idle: {
      backgroundColor: colorTokens['color-bg-danger-tertiary'],
      color: colorTokens['color-text-danger-secondary'],
      iconColor: colorTokens['color-fg-danger'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-danger-tertiary-hover'],
      color: colorTokens['color-text-danger-secondary'],
      iconColor: colorTokens['color-fg-danger'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-danger-tertiary-active'],
      color: colorTokens['color-text-danger-secondary'],
      iconColor: colorTokens['color-fg-danger'],
    },
    disabled: {
      backgroundColor: 'transparent',
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
    },
  },
  success: {
    idle: {
      backgroundColor: colorTokens['color-bg-success-tertiary'],
      color: colorTokens['color-text-success-secondary'],
      iconColor: colorTokens['color-fg-success'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-success-tertiary-hover'],
      color: colorTokens['color-text-success-secondary'],
      iconColor: colorTokens['color-fg-success'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-success-tertiary-active'],
      color: colorTokens['color-text-success-secondary'],
      iconColor: colorTokens['color-fg-success'],
    },
    disabled: {
      backgroundColor: 'transparent',
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
    },
  },
  warning: {
    idle: {
      backgroundColor: colorTokens['color-bg-warning-tertiary'],
      color: colorTokens['color-text-warning-secondary'],
      iconColor: colorTokens['color-fg-warning'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-warning-tertiary-hover'],
      color: colorTokens['color-text-warning-secondary'],
      iconColor: colorTokens['color-fg-warning'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-warning-tertiary-active'],
      color: colorTokens['color-text-warning-secondary'],
      iconColor: colorTokens['color-fg-warning'],
    },
    disabled: {
      backgroundColor: 'transparent',
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
    },
  },
  decorative: {
    idle: {
      backgroundColor: colorTokens['color-bg-decorative-tertiary'],
      color: colorTokens['color-text-decorative-tertiary'],
      iconColor: colorTokens['color-fg-decorative-tertiary'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-decorative-tertiary'],
      color: colorTokens['color-text-decorative-tertiary'],
      iconColor: colorTokens['color-fg-decorative-tertiary'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-decorative-tertiary'],
      color: colorTokens['color-text-decorative-tertiary'],
      iconColor: colorTokens['color-fg-decorative-tertiary'],
    },
    disabled: {
      backgroundColor: 'transparent',
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-decorative-disable'],
    },
  },
  info: {
    idle: {
      backgroundColor: colorTokens['color-bg-info-tertiary'],
      color: colorTokens['color-text-info-secondary'],
      iconColor: colorTokens['color-fg-info'],
    },
    hover: {
      backgroundColor: colorTokens['color-bg-info-tertiary-hover'],
      color: colorTokens['color-text-info-secondary'],
      iconColor: colorTokens['color-fg-info'],
    },
    active: {
      backgroundColor: colorTokens['color-bg-info-tertiary-active'],
      color: colorTokens['color-text-info-secondary'],
      iconColor: colorTokens['color-fg-info'],
    },
    disabled: {
      backgroundColor: 'transparent',
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
    },
  },
}

const textColorsPerButtonStylePerTheme = {
  base: {
    idle: {
      color: colorTokens['color-text-base-secondary'],
      iconColor: colorTokens['color-fg-base'],
    },
    hover: {
      color: colorTokens['color-text-base-secondary'],
      iconColor: colorTokens['color-fg-base'],
    },
    active: {
      color: colorTokens['color-text-base-secondary'],
      iconColor: colorTokens['color-fg-base'],
    },
    disabled: {
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
    },
  },
  danger: {
    idle: {
      color: colorTokens['color-text-danger-secondary'],
      iconColor: colorTokens['color-fg-danger'],
    },
    hover: {
      color: colorTokens['color-text-danger-secondary'],
      iconColor: colorTokens['color-fg-danger'],
    },
    active: {
      color: colorTokens['color-text-danger-secondary'],
      iconColor: colorTokens['color-fg-danger'],
    },
    disabled: {
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
    },
  },
  success: {
    idle: {
      color: colorTokens['color-text-success-secondary'],
      iconColor: colorTokens['color-fg-success'],
    },
    hover: {
      color: colorTokens['color-text-success-secondary'],
      iconColor: colorTokens['color-fg-success'],
    },
    active: {
      color: colorTokens['color-text-success-secondary'],
      iconColor: colorTokens['color-fg-success'],
    },
    disabled: {
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
    },
  },
  warning: {
    idle: {
      color: colorTokens['color-text-warning-secondary'],
      iconColor: colorTokens['color-fg-warning'],
    },
    hover: {
      color: colorTokens['color-text-warning-secondary'],
      iconColor: colorTokens['color-fg-warning'],
    },
    active: {
      color: colorTokens['color-text-warning-secondary'],
      iconColor: colorTokens['color-fg-warning'],
    },
    disabled: {
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
    },
  },
  decorative: {
    idle: {
      color: colorTokens['color-text-decorative-secondary'],
      iconColor: colorTokens['color-fg-decorative-secondary'],
    },
    hover: {
      color: colorTokens['color-text-decorative-secondary'],
      iconColor: colorTokens['color-fg-decorative-secondary'],
    },
    active: {
      color: colorTokens['color-text-decorative-secondary'],
      iconColor: colorTokens['color-fg-decorative-secondary'],
    },
    disabled: {
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-decorative-disable'],
    },
  },
  info: {
    idle: {
      color: colorTokens['color-text-info-secondary'],
      iconColor: colorTokens['color-fg-info'],
    },
    hover: {
      color: colorTokens['color-text-info-secondary'],
      iconColor: colorTokens['color-fg-info'],
    },
    active: {
      color: colorTokens['color-text-info-secondary'],
      iconColor: colorTokens['color-fg-info'],
    },
    disabled: {
      color: colorTokens['color-text-base-disable'],
      iconColor: colorTokens['color-fg-base-disable'],
    },
  },
}

const buttonThemePerVariant: {
  [variant in 'primary' | 'secondary' | 'tertiary']: ButtonTheme
} = {
  primary: primaryColorsPerButtonStylePerTheme,
  secondary: secondaryColorsPerButtonStylePerTheme,
  tertiary: tertiaryColorsPerButtonStylePerTheme,
}

const getSpinnerColor = (disabled: boolean, variant: ButtonVariant, style: ButtonStyle): string => {
  if (disabled) {
    return colorTokens['color-fg-base-disable']
  }

  if (variant === 'text') {
    return colorTokens['color-text-base-secondary']
  }

  return buttonThemePerVariant[variant][style].idle.color
}
