import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useCallback, type MouseEvent } from 'react'
import { colors } from '../../../colors.js'
import { spacing } from '../../../foundation/spacing-tokens.js'
import { useSkin } from '../../../skin/hooks.js'
import { typedMemo } from '../../../util.js'
import { AvatarDecorative, Text, type AvatarDecorativeProps } from '../../atoms/index.js'

export type RowV2DynamicButtonProps = {
  value: string
  label: string
  avatarProps: RowV2DynamicButtonAvatarProps
  onItemClicked: (value: string) => void
  ctaText?: string
}

export type RowV2DynamicButtonAvatarProps = Pick<AvatarDecorativeProps, 'icon' | 'backgroundColor'>

export const RowV2DynamicButton = typedMemo(function RowV2DynamicButton({
  value,
  label,
  avatarProps,
  onItemClicked,
  ctaText,
}: RowV2DynamicButtonProps) {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onItemClicked(event.currentTarget.value)
    },
    [onItemClicked],
  )

  const skin = useSkin()

  return (
    <StyledDynamicButton
      type="button"
      onClick={handleClick}
      value={value}
      key={value}
      border={
        skin.mainActivitySelector?.borderColor
          ? `${spacing[5]} solid ${skin.mainActivitySelector?.borderColor}`
          : 'none'
      }
      backgroundColor={skin.mainActivitySelector?.backgroundColor ?? colors.blue[100]}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: ${spacing[50]};
          `}
        >
          <AvatarDecorative
            css={css`
              flex-shrink: 0;
            `}
            size="50"
            {...avatarProps}
          />
          <Text className="rowV2ButtonLabel" variant="body2" color={colors.blue[900]}>
            {label}
          </Text>
        </div>
        {ctaText ? (
          <Text
            variant="button"
            color={colors.blue[900]}
            css={css`
              text-decoration: underline;
            `}
          >
            {ctaText}
          </Text>
        ) : undefined}
      </div>
    </StyledDynamicButton>
  )
})

export type StyledDynamicButtonProps = {
  border: string
  backgroundColor: string
}

const StyledDynamicButton = styled.button<{ border: string; backgroundColor: string }>`
  text-align: left;
  background: none;
  cursor: pointer;
  padding: ${spacing[40]} ${spacing[50]};
  border-radius: ${spacing[30]};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ border }) => border};

  width: 100%;

  &:hover {
    background-color: ${colors.blue[200]};
  }

  :active {
    background-color: ${colors.blue[200]};

    .rowV2ButtonLabel {
      color: ${colors.blue[900]};
    }
  }
`
