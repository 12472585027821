import styled from '@emotion/styled'
import { failure, success } from '@orus.eu/result'
import { translate } from '@orus.eu/translations'
import { electronicFormatIBAN, friendlyFormatIBAN, isValidIBAN } from 'ibantools'
import { spacing } from '../../../../foundation/spacing-tokens'
import { FlexColumn, FlexRow, type ValidatedTypeMapper } from '../../../atoms'

export const IbanFormContainer = styled(FlexColumn)`
  gap: ${spacing[70]};
`

export const IbanFormFields = styled(FlexColumn)`
  gap: ${spacing[40]};
  width: 100%;
`

export const IbanFormInputContainer = styled(FlexRow)`
  gap: ${spacing[40]};

  margin: 0;

  * {
    flex: 1;
  }
`

export const IBAN_MAPPER: ValidatedTypeMapper<string> = {
  inputType: 'text',
  format: (value) => friendlyFormatIBAN(value) ?? value.trim(),
  formatPlaceholder: (value) => value.trim(),
  parse: (text, language) => {
    const electronicFormat = electronicFormatIBAN(text)
    return electronicFormat && isValidIBAN(electronicFormat)
      ? success(electronicFormat)
      : failure(translate('invalid_sepa', language))
  },
}

export const STRING_MAPPER: ValidatedTypeMapper<string> = {
  inputType: 'text',
  format: (value) => value,
  formatPlaceholder: (value) => value,
  parse: (text) => success(text),
}
