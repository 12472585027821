import { memo } from 'react'
import { useTranslate } from '../../../../localization'
import { Text } from '../../../atoms'

export const Disclaimer = memo<{ gocardlessConfidentialityLink: React.ReactNode }>(function Disclaimer({
  gocardlessConfidentialityLink,
}) {
  const translate = useTranslate()
  const text = translate('gocardless_confidentialty_disclaimer', { gocardlessConfidentialityLink: '__LINK__' })
  const chunks = text.split('__LINK__')
  if (text.endsWith('__LINK__')) {
    return (
      <Text variant="caption">
        {chunks[0]}
        {gocardlessConfidentialityLink}
      </Text>
    )
  } else if (text.startsWith('__LINK__')) {
    return (
      <Text variant="caption">
        {gocardlessConfidentialityLink}
        {chunks[0]}
      </Text>
    )
  } else {
    return (
      <Text variant="caption">
        {chunks[0]}
        {gocardlessConfidentialityLink}
        {chunks[1]}
      </Text>
    )
  }
})
