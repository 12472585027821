import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, type MouseEvent, type ReactNode } from 'react'
import { colorTokens } from '../../foundation/color-tokens.js'
import { spacing } from '../../foundation/spacing-tokens.js'
import { Avatar, Text } from '../atoms/index.js'
import { Tooltip } from '../tooltip/tooltip.js'
import { Checkbox, type OrusCheckboxSize } from './checkbox.js'

type CheckboxFieldTargetElement = 'checkbox' | 'card'

type CheckboxContainerProps = {
  checked: boolean
  onChange: (newValue: boolean, clickedElement: CheckboxFieldTargetElement) => void
  border?: boolean
  children: ReactNode
  disabled?: boolean
  size?: OrusCheckboxSize
  className?: string
  infoTooltip?: string
}

export const CheckboxContainer = memo<CheckboxContainerProps>(function CheckboxContainer(props) {
  const {
    checked,
    onChange,
    children,
    disabled = false,
    border = false,
    size = 'small',
    className,
    infoTooltip,
  } = props

  const handleCardClick = (event: MouseEvent) => {
    // target is the element that was clicked, currentTarget is the element that the event listener is attached to
    if (event.target === event.currentTarget) {
      onChange(!checked, 'card')
    }
  }

  return (
    <Wrapper
      onClick={disabled ? undefined : handleCardClick}
      // Warning: The className 'checkbox-container' is used by the CSS of the child Checkbox
      className={`${className ?? ''} checkbox-container`}
      border={border}
      size={size}
      checked={checked}
      disabled={disabled}
    >
      <Label disabled={disabled} size={size}>
        <Checkbox checked={checked} onChange={() => onChange(!checked, 'checkbox')} size={size} disabled={disabled} />
        <Text variant="body2">{children}</Text>
        {infoTooltip ? (
          <Tooltip title={infoTooltip}>
            <Avatar
              icon="circle-info-regular"
              size="10"
              css={css`
                flex-shrink: 0;
              `}
            />
          </Tooltip>
        ) : null}
      </Label>
    </Wrapper>
  )
})

export const Wrapper = styled.div<{ border: boolean; size: OrusCheckboxSize; checked: boolean; disabled: boolean }>`
  padding: ${({ border }) => (border ? spacing[50] : 0)};
  border-radius: ${spacing[30]};
  outline: ${({ border, checked }) => (border && !checked ? `1px solid ${colorTokens['color-stroke-base']}` : 'none')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background-color: ${({ border, checked }) => (border && checked ? colorTokens['color-bg-base-tertiary'] : 'none')};
`
const Label = styled.label<{ disabled: boolean; size: OrusCheckboxSize }>`
  display: inline-flex;
  align-items: center;
  gap: ${({ size }) => (size === 'small' ? spacing[40] : spacing[50])};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`
