import { createContext } from 'react'

import type { CompoundIconName } from './components'

export type ButtonClickContext = {
  buttonName?: string
  buttonText?: string
  buttonIcon?: CompoundIconName
}

export const TrackButtonClickContext = createContext<(context: ButtonClickContext) => void>((context) => {
  console.log('clickButtonCallback has been used but the context was not available to handle the call', context)
})
