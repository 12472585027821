import styled from '@emotion/styled'
import { TechnicalError } from '@orus.eu/error'
import type { Language } from '@orus.eu/translations'
import { memo, useEffect, useRef, useState } from 'react'
import { borderRadius, spacing } from '../../foundation'
import { useLanguage } from '../../localization/language-context'

export type TrustpilotScoreProps = {
  className?: string
}
export const TrustpilotScore = memo<TrustpilotScoreProps>(function TrustpilotScore({ className }) {
  const trustBoxRef = useRef<HTMLDivElement>(null)
  const trustpilotFacade = useTrustpilotFacade()
  const language = useLanguage()

  useEffect(() => {
    if (!trustBoxRef.current || !trustpilotFacade) return
    trustpilotFacade.loadFromElement(trustBoxRef.current)
  }, [trustpilotFacade])

  if (!trustpilotFacade) return <></>

  const config = trustpilotWidgetConfig[language]

  return (
    <div>
      <TrustpilotScoreContainer
        ref={trustBoxRef}
        className={`trustpilot-widget ${className}`}
        data-locale={config.locale}
        data-template-id="53aa8807dec7e10d38f59f32"
        data-businessunit-id="62ddaf41aba354979b44668c"
        data-style-height={config.height}
        data-style-width={config.width}
        data-theme="light"
        data-font-family="Poppins"
        data-text-color="#001C13"
      >
        <a href={config.url} target="_blank" rel="noreferrer">
          Trustpilot
        </a>
      </TrustpilotScoreContainer>
    </div>
  )
})

/**
 * The subset of the truspilot API that we use
 */
type TrustpilotFacade = {
  /**
   * Replaces the content of a div by the trustpilot widget. Widget configuration is passed
   * using data-attributes of the div.
   */
  loadFromElement(div: HTMLDivElement): void
}

/**
 * Triggers the loading of trustpilot and returns the facade to it's API, or undefined
 * while it's loading or if it fails to load.
 */
function useTrustpilotFacade(): TrustpilotFacade | undefined {
  const [trustpilotFacade, setTrustpilotFacade] = useState<TrustpilotFacade | undefined>()

  useEffect(() => {
    getTrustpilotFacadePromise()
      .then(setTrustpilotFacade)
      .catch((err) => {
        console.error('Failed to load trustpilot', err)
      })
  }, [])

  return trustpilotFacade
}

/**
 * Ensure trustpilot is loaded and returns a promise to it's API facade
 */
function getTrustpilotFacadePromise(): Promise<TrustpilotFacade> {
  if (!trustpilotFacadePromise) {
    trustpilotFacadePromise = new Promise((resolve) => {
      const script = document.createElement('script')
      script.setAttribute('src', 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js')
      script.addEventListener(
        'load',
        () => {
          const { Trustpilot: trustpilotFacade } = window as Window & { Trustpilot?: TrustpilotFacade }
          if (!trustpilotFacade) {
            throw new TechnicalError('Trustpilot object not found')
          }
          resolve(trustpilotFacade)
        },
        {
          once: true,
        },
      )
      document.head.appendChild(script)
    })
  }
  return trustpilotFacadePromise
}

let trustpilotFacadePromise: Promise<TrustpilotFacade> | undefined = undefined

const TrustpilotScoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${spacing[60]} 0;
  background-color: #effbf9;
  border-radius: ${borderRadius[30]};
`

type WidgetConfig = {
  locale: string
  url: string
  width: string
  height: string
}

const trustpilotWidgetConfig: Record<Language, WidgetConfig> = {
  fr: {
    locale: 'fr-FR',
    url: 'https://fr.trustpilot.com/review/orus.eu',
    width: '148px',
    height: '86px',
  },
  es: {
    locale: 'es-ES',
    url: 'https://es.trustpilot.com/review/orus.eu',
    width: '185px',
    height: '100px',
  },
}
