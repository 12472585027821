import { allProducts, type Product } from '@orus.eu/product'
import {
  AbstractDimension,
  activitySpecificAnswersDimension,
  BooleanDimension,
  complementaryActivitiesDimension,
  esRcphQuoteDimension,
  esRcphSelectedDimension,
  mrphQuoteV2Dimension,
  mrphSelectedDimension,
  mrpwQuoteDimension,
  mrpwSelectedDimension,
  mutaQuoteDimension,
  mutaSelectedDimension,
  rcdaQuoteDimension,
  rcdaSelectedDimension,
  rcphQuoteV2Dimension,
  rcphSelectedDimension,
  type PartialDimensionnedState,
  type TypeOfDimension,
} from './dimension/index.js'
import type { productSelectionDimensions } from './product.js'
export type { ActivableProductInformationIdTag, DimensionTag } from './dimension/tags.js'

export * from './dimension/index.js'
export * from './documents/index.js'
export * from './non-endorsement-changes.js'
export * from './pricing/invoice-item-amount-factories.js'
export * from './product-attribute-description.js'
export * from './product.js'
export * from './quote-editor-ui/index.js'
export * from './subscription-ui/index.js'
export * from './tracking/index.js'

export function isProductSelected(
  state: PartialDimensionnedState<typeof productSelectionDimensions>,
  product: Product,
): boolean {
  return !!state[productSelectedDimension[product].name]
}

export type ProductQuoteName = 'mrphQuoteV2' | 'mrpwQuote' | 'mutaQuote' | 'rcdaQuote' | 'rcphQuoteV2' | 'esRcphQuote'

export type QuoteTypeByProduct<P extends Product> = TypeOfDimension<(typeof productQuoteDimension)[P]>

export const productQuoteDimension = {
  mrph: mrphQuoteV2Dimension,
  mrpw: mrpwQuoteDimension,
  muta: mutaQuoteDimension,
  rcda: rcdaQuoteDimension,
  rcph: rcphQuoteV2Dimension,
  'es-rcph': esRcphQuoteDimension,
} as const satisfies Record<Product, AbstractDimension>

export const productQuoteDimensionNames: Record<Product, ProductQuoteName> = {
  mrph: mrphQuoteV2Dimension.name,
  mrpw: mrpwQuoteDimension.name,
  muta: mutaQuoteDimension.name,
  rcda: rcdaQuoteDimension.name,
  rcph: rcphQuoteV2Dimension.name,
  'es-rcph': esRcphQuoteDimension.name,
}

export const productSelectedDimension = {
  mrph: mrphSelectedDimension,
  mrpw: mrpwSelectedDimension,
  muta: mutaSelectedDimension,
  rcda: rcdaSelectedDimension,
  rcph: rcphSelectedDimension,
  'es-rcph': esRcphSelectedDimension,
} as const satisfies Record<Product, BooleanDimension<`${string}Selected`>>

export function getSelectedProducts(state: PartialDimensionnedState<typeof productSelectionDimensions>): Product[] {
  return allProducts.filter((product) => isProductSelected(state, product))
}

export type RiskCarrier = (typeof allRiskCarriers)[number]
export const allRiskCarriers = ['hiscox', 'wakam', 'acheel', 'axeria'] as const

export function getProductRiskCarrier(product: Product): RiskCarrier {
  switch (product) {
    case 'mrph':
    case 'rcph':
    case 'es-rcph':
      return 'hiscox'
    case 'mrpw':
      return 'wakam'
    case 'muta':
      return 'acheel'
    case 'rcda':
      return 'axeria'
  }
}

export function getProductsRiskCarriers(products: Product[]): RiskCarrier[] {
  return [...new Set(products.map((product) => getProductRiskCarrier(product)))]
}

export const activityRelatedInputDimensionsToReset = {
  [rcdaSelectedDimension.name]: null,
  [mutaSelectedDimension.name]: null,
  [rcphSelectedDimension.name]: null,
  [mrpwSelectedDimension.name]: null,
  [mrphSelectedDimension.name]: null,
  [activitySpecificAnswersDimension.name]: {},
  [complementaryActivitiesDimension.name]: [],
}
