import FaviconDark from '../assets/logo/Favicon-Dark.svg'
import FaviconLight from '../assets/logo/Favicon-Light.svg'
import SubmarkDark from '../assets/logo/Logo-Submark-Dark.png'
import SubmarkWhite from '../assets/logo/Logo-Submark-White.png'
import SubmarkVector from '../assets/logo/Logo-Submark.svg'
import WordmarkDark from '../assets/logo/Logo-Wordmark-Dark.png'
import WordmarkWhite from '../assets/logo/Logo-Wordmark-White.png'
import WordmarkVector from '../assets/logo/Logo-Wordmark.svg'

export const logo = {
  SubmarkDark,
  SubmarkWhite,
  SubmarkVector,
  WordmarkDark,
  WordmarkWhite,
  WordmarkVector,
  FaviconLight,
  FaviconDark,
} as const
