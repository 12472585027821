import { isOperatingZone } from '@orus.eu/operating-zone'
import { isLanguage, type Language } from '@orus.eu/translations'
import { memo, useMemo, useState, type ReactNode } from 'react'
import { DefaultLanguageProvider } from './language-context'

export const DefaultLanguageProviderWrapper = memo<{ children: ReactNode }>(function DefaultLanguageProviderWrapper({
  children,
}) {
  const [language, setLanguage] = useState<Language>(getInitialDefaultLanguage())

  const languageContextValue = useMemo(() => ({ language, setLanguage }), [language, setLanguage])

  return <DefaultLanguageProvider value={languageContextValue}>{children}</DefaultLanguageProvider>
})

function getInitialDefaultLanguage(): Language {
  const url = new URL(document.location.href)

  const languageUrlParameter = url.searchParams.get('language')
  if (languageUrlParameter && isLanguage(languageUrlParameter)) {
    return languageUrlParameter
  }

  const operatingZoneUrlParameter = url.searchParams.get('operatingZone')
  if (operatingZoneUrlParameter && isOperatingZone(operatingZoneUrlParameter)) {
    return operatingZoneUrlParameter
  }

  return 'fr'
}
