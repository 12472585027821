import { createElement, Fragment, isValidElement, memo } from 'react'

export const typedMemo: <T>(c: T) => T = memo

// hardcoded while we service only french customers
export const locale = 'fr'

export function getFileDisplayName(fileName: string): string {
  if (fileName.startsWith('Orus - ')) fileName = fileName.substring('Orus - '.length)
  if (fileName.endsWith('.pdf')) fileName = fileName.substring(0, fileName.length - '.pdf'.length)
  return fileName
}

export const isFragment = (element: React.ReactNode): boolean => {
  return isValidElement(element) && (element.type === Fragment || element.type === createElement(Fragment).type)
}
