import { TRPCClientError } from '@trpc/client'
import type { InferrableClientTypes } from '@trpc/server/unstable-core-do-not-import'
import { CrashPage } from '../components/organisms/crash-page'
import { NotFound } from '../components/organisms/not-found'
import TemporaryFailureScreen from '../components/organisms/temporary-failure-screen'
import { Unauthorized } from '../components/organisms/unauthorized'
import { logger } from './logger'

export function TrpcErrorHandler({ err }: { err: TRPCClientError<InferrableClientTypes> }): JSX.Element | null {
  switch (err.data?.code) {
    case 'NOT_FOUND':
      return <NotFound message={err.message} />
    case 'FORBIDDEN':
    case 'UNAUTHORIZED':
      return <Unauthorized message={err.message} />
    case 'TIMEOUT':
    case 'CLIENT_CLOSED_REQUEST':
    case 'CONFLICT':
    case 'PRECONDITION_FAILED':
      return <TemporaryFailureScreen />
    default:
      logger.error(err)
      return <CrashPage />
  }
}
