import { useOpenChat, useTranslate } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { GenericProblemMessage } from './generic-problem-message'

export const InvalidLinkMessage = memo(function InvalidLinkMessage() {
  const translate = useTranslate()
  const openChat = useOpenChat()
  return (
    <GenericProblemMessage
      title={translate('problem_invalid_link_title')}
      principalMessage={translate('problem_invalid_link_principal_message')}
      firstSubText={translate('problem_invalid_link_first_subtext')}
      secondSubText={translate('problem_invalid_link_second_subtext')}
      buttonText={translate('contact_customer_support')}
      onButtonClick={openChat}
    />
  )
})
