import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { createLink } from '@tanstack/react-router'
import { memo } from 'react'
import { spacing } from '../../foundation'
import { logo } from '../../foundation/logo'
import { useTranslate } from '../../localization'
import { StyledBackofficeNavbar, StyledClientAppNavbar, type NavbarProps } from './common'

type NavbarTitleProps =
  | (NavbarProps & { variant: 'backoffice' | 'clientapp' })
  | { variant: 'partner'; organization: string }

export const NavbarTitle = memo<NavbarTitleProps>(function NavbarTitle(props: NavbarTitleProps) {
  const { variant } = props

  switch (variant) {
    case 'backoffice':
      return <BackofficeNavbarTitle />
    case 'partner':
      return <PartnerNavbarTitle organization={props.organization} />
    case 'clientapp':
      return <ClientAppNavbarTitle />
  }
})

const BackofficeNavbarTitle = memo(function BackofficeNavbarTitle() {
  return (
    <StyledBackofficeNavbar>
      <LogoWrapperTanStackLink to="/bak/home" isLogoLarge={true}>
        <img src={logo['FaviconDark']} alt={'FaviconDark'} aria-label="Accueil" />
      </LogoWrapperTanStackLink>
    </StyledBackofficeNavbar>
  )
})

const PartnerNavbarTitle = memo<{ organization: string }>(function PartnerNavbarTitle({ organization }) {
  return (
    <StyledBackofficeNavbar>
      <LogoWrapperTanStackLink to="/partner/$organization/home" params={{ organization }} isLogoLarge={true}>
        <img src={logo['FaviconDark']} alt={'FaviconDark'} aria-label="Accueil" />
      </LogoWrapperTanStackLink>
    </StyledBackofficeNavbar>
  )
})

const ClientAppNavbarTitle = memo(function ClientAppNavbarTitle() {
  const translate = useTranslate()

  return (
    <StyledClientAppNavbar
      css={css`
        padding: 0 ${spacing[40]};
      `}
    >
      <LogoWrapperTanStackLink to="/">
        <img src={logo['WordmarkDark']} alt={'WordmarkDark'} aria-label={translate('welcome_logo')} />
      </LogoWrapperTanStackLink>
    </StyledClientAppNavbar>
  )
})

const LogoWrapper = styled.a<{ isLogoLarge?: boolean }>`
  background: none;
  outline: none;
  text-decoration: none;
  border: none;
  cursor: pointer;

  img {
    display: block;
    height: ${({ isLogoLarge }) => (isLogoLarge ? '32px' : '24px')};
  }
`

const LogoWrapperTanStackLink = createLink(LogoWrapper)
