import { css } from '@emotion/react'

import { memo, useCallback, useState } from 'react'
import { colorTokens } from '../../../foundation'
import { useDialogVisibility, useScreenType } from '../../../hooks'
import { useTranslate } from '../../../localization'
import { Dialog } from '../../../patterns/dialog/dialog'
import { Text } from '../../atoms'
import { Button } from '../../button/button'
import { ValidatedTextFieldFormField } from '../../molecules'
import { Disclaimer } from './common/GoCardlessDisclaimer'
import { IbanFormatter } from './common/iban-formatter'
import { SepaCustomerDataInputs } from './common/SepaCustomerDataInputs'
import { IBAN_MAPPER, IbanFormContainer, IbanFormFields } from './common/utils'

export type CustomerData = {
  firstName: string
  lastName: string
  email: string
  address: string
  city: string
  postalCode: string
}

type CheckoutIbanWizardProps = {
  initialCustomerData: CustomerData
  iban: string | undefined
  onIbanChange: (iban: string | undefined) => void
  onValidate: (data: CustomerData) => void
  ibanCustomerData: CustomerData
}

export const CheckoutIbanWizard = memo(function CheckoutIbanWizard({
  onValidate,
  initialCustomerData,
  iban,
  onIbanChange,
  ibanCustomerData,
}: CheckoutIbanWizardProps) {
  const translate = useTranslate()
  const screenVariant = useScreenType()
  const {
    firstName: initialFirstName,
    lastName: initialLastName,
    email: initialEmail,
    address: initialAddress,
    city: initialCity,
    postalCode: initialPostalCode,
  } = initialCustomerData
  const [firstName, setFirstName] = useState<string | undefined>(initialFirstName)
  const [lastName, setLastName] = useState<string | undefined>(initialLastName)
  const [email, setEmail] = useState<string | undefined>(initialEmail)
  const [address, setAddress] = useState<string | undefined>(initialAddress)
  const [city, setCity] = useState<string | undefined>(initialCity)
  const [postalCode, setPostalCode] = useState<string | undefined>(initialPostalCode)

  const {
    hide: hideSepaInfosDialog,
    show: showSepaInfosDialog,
    visible: isSepaInfosDialogVisible,
  } = useDialogVisibility('sepa-infos')

  const handleValidate = useCallback(() => {
    if (!firstName || !lastName || !email || !address || !city || !postalCode) {
      return
    }
    onValidate({
      firstName: firstName,
      lastName: lastName,
      email: email,
      address: address,
      city: city,
      postalCode: postalCode,
    })

    hideSepaInfosDialog()
  }, [lastName, email, address, city, postalCode, onValidate, firstName, hideSepaInfosDialog])

  const gocardlessConfidentialityLink = (
    <a href="https://gocardless.com/fr-fr/privacy/" target="_blank" rel="noopener noreferrer">
      {translate('gocardless_confidentiality_link_text')}
    </a>
  )

  return (
    <>
      <IbanFormContainer>
        <IbanFormFields>
          <ValidatedTextFieldFormField
            size="large"
            label="IBAN"
            value={iban}
            placeholder={translate('IBAN_placeholder')}
            onChange={onIbanChange}
            mapper={IBAN_MAPPER}
            slotProps={{
              input: {
                inputComponent: IbanFormatter,
              },
            }}
            autoFocus
          />
        </IbanFormFields>

        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div>
            <Text variant="body2Medium">{translate('sepa_mandate_holder')}</Text>
            <Text variant="body2" color={colorTokens['color-text-base-basic']}>
              {ibanCustomerData.firstName}&nbsp;{ibanCustomerData.lastName}
            </Text>
            <Text variant="body2" color={colorTokens['color-text-base-basic']}>
              {ibanCustomerData.email}
            </Text>
            <Text variant="body2" color={colorTokens['color-text-base-basic']}>
              {ibanCustomerData.address}&nbsp;{ibanCustomerData.postalCode}&nbsp;{ibanCustomerData.city}
            </Text>
          </div>

          <Button
            variant="secondary"
            size={screenVariant === 'desktop' ? 'medium' : 'large'}
            onClick={showSepaInfosDialog}
          >
            {translate('modify')}
          </Button>
        </div>

        <Disclaimer gocardlessConfidentialityLink={gocardlessConfidentialityLink} />
      </IbanFormContainer>

      {isSepaInfosDialogVisible ? (
        <Dialog
          size="medium"
          title={translate('owner_information')}
          onClose={hideSepaInfosDialog}
          primaryActionLabel={translate('validate')}
          primaryActionDisabled={
            firstName === '' || lastName === '' || email === '' || address === '' || city === '' || postalCode === ''
          }
          secondaryActionLabel={translate('cancel')}
          onPrimaryAction={handleValidate}
          onSecondaryAction={hideSepaInfosDialog}
        >
          <IbanFormFields>
            <SepaCustomerDataInputs
              onFirstNameChange={setFirstName}
              firstNameValue={firstName ?? ''}
              onLastNameChange={setLastName}
              lastNameValue={lastName ?? ''}
              onAddressChange={setAddress}
              addressValue={address ?? ''}
              onEmailChange={setEmail}
              emailValue={email ?? ''}
              onPostalCodeChange={setPostalCode}
              postalCodeValue={postalCode ?? ''}
              onCityChange={setCity}
              cityValue={city ?? ''}
            />
          </IbanFormFields>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  )
})
