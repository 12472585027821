import styled from '@emotion/styled'
import { memo, type ReactNode } from 'react'
import { colorTokens, spacing } from '../../foundation'
import { Text } from '../atoms'
import type { badgeVariants } from './badge.util'

export type BadgeVariant = (typeof badgeVariants)[number]

export type BadgeProps = {
  children: ReactNode
  variant?: BadgeVariant
  dark?: boolean
  className?: string
  minWidth?: string
}

export const Badge = memo<BadgeProps>(function Badge(props: BadgeProps) {
  const { children, variant = 'neutral', dark, className, minWidth } = props

  const colors = colorsPerVariant[variant][dark ? 'dark' : 'light']

  return (
    <BadgeContainer className={className} role="status" colors={colors} minWidth={minWidth}>
      <Text variant="captionMedium">{children}</Text>
    </BadgeContainer>
  )
})

const BadgeContainer = styled.div<{ colors: Colors; minWidth?: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: ${({ minWidth }) => minWidth ?? 'max-content'};
  max-width: max-content;

  padding: ${spacing[10]} ${spacing[20]};
  border-radius: ${spacing[20]};

  color: ${({ colors }) => colors.textColor};
  background-color: ${({ colors }) => colors.backgroundColor};
`

type Colors = { backgroundColor: string; textColor: string }

const colorsPerVariant: {
  [variant in BadgeVariant]: { light: Colors; dark: Colors }
} = {
  neutral: {
    light: {
      textColor: colorTokens['color-text-base-main'],
      backgroundColor: colorTokens['color-bg-neutral-inverse'],
    },
    dark: {
      textColor: colorTokens['color-text-base-inverse'],
      backgroundColor: colorTokens['color-bg-neutral'],
    },
  },
  blue: {
    light: {
      textColor: colorTokens['color-text-base-main'],
      backgroundColor: colorTokens['color-bg-info-inverse'],
    },
    dark: {
      textColor: colorTokens['color-text-base-inverse'],
      backgroundColor: colorTokens['color-bg-info-primary'],
    },
  },
  green: {
    light: {
      textColor: colorTokens['color-text-base-main'],
      backgroundColor: colorTokens['color-bg-success-inverse'],
    },
    dark: {
      textColor: colorTokens['color-text-base-inverse'],
      backgroundColor: colorTokens['color-bg-success-primary'],
    },
  },
  orange: {
    light: {
      textColor: colorTokens['color-text-base-main'],
      backgroundColor: colorTokens['color-bg-warning-inverse'],
    },
    dark: {
      textColor: colorTokens['color-text-base-inverse'],
      backgroundColor: colorTokens['color-bg-warning-primary'],
    },
  },
  red: {
    light: {
      textColor: colorTokens['color-text-base-main'],
      backgroundColor: colorTokens['color-bg-danger-inverse'],
    },
    dark: {
      textColor: colorTokens['color-text-base-inverse'],
      backgroundColor: colorTokens['color-bg-danger-primary'],
    },
  },

  periwinkle: {
    light: {
      textColor: colorTokens['color-text-decorative-main'],
      backgroundColor: colorTokens['color-bg-decorative-1'],
    },
    dark: {
      textColor: colorTokens['color-text-decorative-1'],
      backgroundColor: colorTokens['color-bg-base-inverse'],
    },
  },

  sky: {
    light: {
      textColor: colorTokens['color-text-decorative-main'],
      backgroundColor: colorTokens['color-bg-decorative-2'],
    },
    dark: {
      textColor: colorTokens['color-text-decorative-2'],
      backgroundColor: colorTokens['color-bg-base-inverse'],
    },
  },

  mindaro: {
    light: {
      textColor: colorTokens['color-text-decorative-main'],
      backgroundColor: colorTokens['color-bg-decorative-3'],
    },
    dark: {
      textColor: colorTokens['color-text-decorative-3'],
      backgroundColor: colorTokens['color-bg-base-inverse'],
    },
  },

  jasmine: {
    light: {
      textColor: colorTokens['color-text-decorative-main'],
      backgroundColor: colorTokens['color-bg-decorative-4'],
    },
    dark: {
      textColor: colorTokens['color-text-decorative-4'],
      backgroundColor: colorTokens['color-bg-base-inverse'],
    },
  },

  peach: {
    light: {
      textColor: colorTokens['color-text-decorative-main'],
      backgroundColor: colorTokens['color-bg-decorative-5'],
    },
    dark: {
      textColor: colorTokens['color-text-decorative-5'],
      backgroundColor: colorTokens['color-bg-base-inverse'],
    },
  },
}
