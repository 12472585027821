import type { PartialDimensionnedState } from '../abstract-dimension.js'
import {
  mutaGuaranteesGroups,
  type MutaGuaranteeGroupType,
  type PolicyGuarantee,
  type PolicyOffer,
  type ProductInformation,
} from '../quote-offer-information-dimension.js'
import { mutaFormulaDimension } from './muta-formula-dimension.js'
import { getMutaGuaranteesForGuaranteeLimit } from './muta-guarantee-limit.js'
import { mutaGuaranteeProductInformation } from './muta-guarantee-text.js'
import { getMutaProductAttributes, mutaProductAttributesDependencies } from './muta-product-attributes.js'

export const mutaSoinsCourant: PolicyOffer = { type: 'SoinsCourants', name: 'Soins courants' }
export const mutaMedecineDouce: PolicyOffer = { type: 'MedecineDouce', name: 'Médecine douce' }
export const mutaAppareillage: PolicyOffer = { type: 'Appareillage', name: 'Appareillage' }
export const mutaDentaire: PolicyOffer = { type: 'Dentaire', name: 'Dentaire' }
export const mutaOptique: PolicyOffer = { type: 'Optique', name: 'Optique' }
export const mutaAuditif: PolicyOffer = { type: 'Auditif', name: 'Auditif' }
export const mutaHospitalisation: PolicyOffer = { type: 'Hospitalisation', name: 'Hospitalisation' }
export const mutaAssistance: PolicyOffer = { type: 'Assistance', name: 'Assistance' }

export const mutaProductInformationDependencies = [mutaFormulaDimension, ...mutaProductAttributesDependencies] as const

export const mutaProductDetails = mutaGuaranteesGroups.reduce(
  (builtProducts, type) => ({
    ...builtProducts,
    [type]: {
      ...mutaGuaranteeProductInformation[type],
      type,
      offer: getMutaPolicyOffer(type),
    },
  }),
  {} as Record<MutaGuaranteeGroupType, Omit<PolicyGuarantee, 'guaranteeLimits'>>,
)

export function getMutaProductInformation(
  state: PartialDimensionnedState<typeof mutaProductInformationDependencies>,
): ProductInformation | undefined {
  const { mutaFormula } = state
  if (mutaFormula == undefined) return undefined

  const productAttributes = getMutaProductAttributes(state)
  if (!productAttributes) return undefined

  const guarantees = mutaGuaranteesGroups.map((type): PolicyGuarantee => {
    const guaranteeLimits = getMutaGuaranteesForGuaranteeLimit(mutaFormula, type)
    return {
      ...mutaProductDetails[type],
      guaranteeLimits: [
        {
          limit: guaranteeLimits.type === 'single' ? guaranteeLimits.value : guaranteeLimits.value.optam,
          isGuaranteeFixed: true,
          name: `Plafond d'indemnisation`,
        },
      ],
    }
  })

  const offers = [
    mutaSoinsCourant,
    mutaMedecineDouce,
    mutaAppareillage,
    mutaDentaire,
    mutaOptique,
    mutaAuditif,
    mutaHospitalisation,
    mutaAssistance,
  ]

  return {
    product: 'muta',
    name: 'Mutuelle santé',
    attributes: productAttributes,
    offers,
    guarantees,
    optionalGuarantees: [],
  }
}

function getMutaPolicyOffer(type: MutaGuaranteeGroupType): PolicyOffer {
  switch (type) {
    case 'MedecinGeneraliste':
    case 'Paramedicaux':
    case 'AnalysesExamsLaboratoires':
    case 'Medicaments':
    case 'Transport':
      return mutaSoinsCourant
    case 'MedecineNaturelle':
    case 'ActesDePrevention':
      return mutaMedecineDouce
    case 'PetitAppareillage':
    case 'ProthesesEtAppareillages':
      return mutaAppareillage
    case 'SoinsDentaires':
    case 'Orthodontie':
    case 'ProthesesDentaires':
    case 'DentaireNonPrisEnCharge':
      return mutaDentaire
    case 'MontureVerresSimples':
    case 'MontureVerresComplexes':
    case 'Lentilles':
      return mutaOptique
    case 'AppareillageEtProthesesAuditives':
    case 'AidesAuditives':
      return mutaAuditif
    case 'FraisDeSejour':
    case 'HonorairesPraticiens':
    case 'HonorairesEtablissements':
    case 'ChambreParticuliere':
    case 'SejourAccompagnant':
    case 'ConfortHospitalier':
    case 'HospitalisationDomicile':
      return mutaHospitalisation
    case 'TeleconsultationMedicale':
    case 'CoachingBienEtre':
    case 'AccompagnementPsychologique':
    case 'AideDomicile':
    case 'TransportTaxi':
      return mutaAssistance
  }
}
