import { X0, amountToString, newAmount, rateGt, type Amount, type FinancialRate } from '@orus.eu/amount'
import { nbsp } from '@orus.eu/char'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import type { PartialDimensionnedState } from '../abstract-dimension.js'
import type {
  GuaranteeLimitModel,
  PolicyDeductible,
  PolicyOptionalGuaranteeLimit,
  RcProOptionalGuaranteesType,
} from '../quote-offer-information-dimension.js'
import type { RcProCyberOptionalGuaranteeType } from './rcph-quote-dimension.js'
import { rcphTdmiLoiDimension } from './rcph-tdmi-loi-dimension.js'

const plafondIndemnisationDefaultString = 'Plafond d’indemnisation'

const rceDeductibleMinAmount = newAmount('0')
const rceDeductibleMaxAmount = newAmount('500')
const rceBodilyHarmAmount = newAmount('8000000')
const rceMaterialDamage = newAmount('1500000')
const worldDeductibleAmount = newAmount('15000')
const cyberDeductibleAmount = newAmount('1000')
export const rcphTdmiDeductible = newAmount('300')

export const rcProOptionalGuaranteeDeductibleDescriptionsFactories: {
  [key in RcProOptionalGuaranteesType]: PolicyDeductible
} = {
  rce: {
    name: 'Franchise',
    descriptions: [
      {
        deductible: `${amountToString(rceDeductibleMinAmount, {
          displayDecimals: false,
          addCurrency: true,
        })} ou ${amountToString(rceDeductibleMaxAmount, {
          displayDecimals: false,
          addCurrency: true,
        })}`,
        description: `${nbsp}(selon la franchise affichée sur votre devis).`,
      },
    ],
  },
  world: {
    name: 'Franchise',
    descriptions: [
      {
        deductible: `${amountToString(worldDeductibleAmount, {
          displayDecimals: false,
          addCurrency: true,
        })}`,
        description: `${nbsp}par sinistre.`,
      },
    ],
  },
  cyber: {
    name: 'Franchise',
    descriptions: [
      {
        deductible: `${amountToString(cyberDeductibleAmount, {
          displayDecimals: false,
          addCurrency: true,
        })}`,
      },
    ],
  },
  tdmi: {
    name: 'Franchise',
    descriptions: [
      {
        deductible: `${amountToString(rcphTdmiDeductible, {
          displayDecimals: false,
          addCurrency: true,
        })}`,
      },
    ],
  },
  mrpSmallOffice: {
    name: 'Franchise',
    descriptions: [
      {
        deductible: 'Par garantie',
      },
    ],
  },
}

export const rcProCyberOptionalGuaranteeDeductibleDescriptionsFactories: {
  [key in RcProCyberOptionalGuaranteeType]: PolicyDeductible
} = {
  cyberDommagesPe: {
    name: 'Franchise',
    descriptions: [
      {
        deductible: `${amountToString(cyberDeductibleAmount, {
          displayDecimals: false,
          addCurrency: true,
        })}`,
      },
    ],
  },
  cyberDefaillanceFournisseur: {
    name: 'Franchise',
    descriptions: [
      {
        deductible: `${amountToString(cyberDeductibleAmount, {
          displayDecimals: false,
          addCurrency: true,
        })}`,
      },
    ],
  },
  cyberDommagesTiers: {
    name: 'Franchise',
    descriptions: [
      {
        deductible: `${amountToString(cyberDeductibleAmount, {
          displayDecimals: false,
          addCurrency: true,
        })}`,
      },
    ],
  },
  cyberFraudePiratage: {
    name: 'Franchise',
    descriptions: [
      {
        deductible: `${amountToString(cyberDeductibleAmount, {
          displayDecimals: false,
          addCurrency: true,
        })}`,
      },
    ],
  },
}

function computeRceLimit(amount: Amount, description: string): GuaranteeLimitModel {
  return {
    limit: `Jusqu'à ${amountToString(amount, { displayDecimals: false, addCurrency: true })}`,
    isGuaranteeFixed: false,
    description,
  }
}

function computeWorldLimit(): GuaranteeLimitModel {
  return {
    isGuaranteeFixed: true,
    description: `Cette option fait que vos garanties s'appliquent dans le monde entier y compris US/Canada mais ne modifie pas leurs plafonds.`,
    limit: undefined,
  }
}

function computeMrpSmallOfficeLimit(): GuaranteeLimitModel {
  return {
    isGuaranteeFixed: true,
    limit: `Par garantie`,
  }
}

function computeCyberTdmiLimit(amount: Amount): GuaranteeLimitModel {
  return {
    limit: `${amountToString(amount, { displayDecimals: false, addCurrency: true })}`,
    isGuaranteeFixed: true,
  }
}

export const cyberLimit = newAmount('100000')
export const cyberDommagesPeLimit = newAmount('100000')
export const cyberDefaillanceFournisseurLimit = newAmount('100000')
export const cyberDommagesTiersLimit = newAmount('100000')
export const cyberFraudePiratageLimit = newAmount('35000')

export const rcProOptionalGuaranteeLimitDependencies = [rcphTdmiLoiDimension] as const

export const rcProOptionalGuaranteeLimitFactories: {
  [key in RcProOptionalGuaranteesType]: (
    state: PartialDimensionnedState<typeof rcProOptionalGuaranteeLimitDependencies>,
  ) => PolicyOptionalGuaranteeLimit
} = {
  rce: () => ({
    name: plafondIndemnisationDefaultString,
    limits: [
      computeRceLimit(rceBodilyHarmAmount, `${nbsp}par sinistre pour les dommages corporels`),
      computeRceLimit(rceMaterialDamage, `${nbsp}pour les dommages matériels`),
    ],
  }),
  world: () => ({
    name: plafondIndemnisationDefaultString,
    limits: [computeWorldLimit()],
  }),
  cyber: () => ({ name: plafondIndemnisationDefaultString, limits: [computeCyberTdmiLimit(cyberLimit)] }),
  tdmi: ({ rcphTdmiLoi }) => ({
    name: plafondIndemnisationDefaultString,
    limits: [computeCyberTdmiLimit(newAmount(checkDefinedAndNotNull(rcphTdmiLoi)))],
  }),
  mrpSmallOffice: () => ({ name: plafondIndemnisationDefaultString, limits: [computeMrpSmallOfficeLimit()] }),
}

export const rcProCyberOptionalGuaranteeLimitFactories: {
  [key in RcProCyberOptionalGuaranteeType]: PolicyOptionalGuaranteeLimit
} = {
  cyberDommagesPe: { name: plafondIndemnisationDefaultString, limits: [computeCyberTdmiLimit(cyberDommagesPeLimit)] },
  cyberDefaillanceFournisseur: {
    name: plafondIndemnisationDefaultString,
    limits: [computeCyberTdmiLimit(cyberDefaillanceFournisseurLimit)],
  },
  cyberDommagesTiers: {
    name: plafondIndemnisationDefaultString,
    limits: [computeCyberTdmiLimit(cyberDommagesTiersLimit)],
  },
  cyberFraudePiratage: {
    name: plafondIndemnisationDefaultString,
    limits: [computeCyberTdmiLimit(cyberFraudePiratageLimit)],
  },
}

export const rcProOptionalGuaranteeIsRecommanded: {
  [key in RcProOptionalGuaranteesType]: (canadaOrUSARevenuesPercentage: FinancialRate | null) => boolean
} = {
  rce: (_canadaOrUSARevenuesPercentage) => true,
  mrpSmallOffice: () => false,
  world: (canadaOrUSARevenuesPercentage) =>
    canadaOrUSARevenuesPercentage && rateGt(canadaOrUSARevenuesPercentage, X0) ? true : false,
  cyber: () => false,
  tdmi: () => false,
}

export const rcProCyberOptionalGuaranteeIsRecommanded: {
  [key in RcProCyberOptionalGuaranteeType]: (canadaOrUSARevenuesPercentage: FinancialRate | null) => boolean
} = {
  cyberDommagesPe: () => false,
  cyberDefaillanceFournisseur: () => false,
  cyberDommagesTiers: () => false,
  cyberFraudePiratage: () => false,
}
