import { css } from '@emotion/react'
import { memo, useCallback, useState } from 'react'
import { Checkbox } from '../checkbox'
import { Spinner } from '../spinner'

type ProcessingCheckboxProps = {
  /**
   * Actual value after potential processing is complete
   */
  value: boolean
  /**
   * Called when user attempts to change the value. Must return a boolean indicating if change
   * is possible
   */
  onChange: (newValue: boolean) => boolean
}

/**
 * A checkbox that is replaced by a spinner while we wait for the value to change after the user
 * click
 */
export const ProcessingCheckbox = memo<ProcessingCheckboxProps>(function OptionCheckbox({ value, onChange }) {
  const [checked, setChecked] = useState(value)
  const processing = checked !== value

  const handleChange = useCallback(
    (newValue: boolean) => {
      if (onChange(newValue)) setChecked(newValue)
    },
    [onChange],
  )

  return (
    <span
      css={css`
        position: relative;
        display: flex;
      `}
    >
      <Checkbox
        css={css`
          visibility: ${processing ? 'hidden' : 'visible'};
        `}
        size="medium"
        checked={checked}
        onChange={handleChange}
        disabled={processing}
      />
      <div
        css={css`
          display: ${processing ? 'flex' : 'none'};
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
      >
        <Spinner size="40" />
      </div>
    </span>
  )
})
