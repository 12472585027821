import styled from '@emotion/styled'
import { memo, useMemo, type ReactNode } from 'react'
import { borderRadius } from '../../foundation/border-radius-tokens.js'
import { colorTokens } from '../../foundation/color-tokens.js'
import { spacing } from '../../foundation/spacing-tokens.js'
import { Avatar, AvatarContext, Text, type CompoundIconName, type IconSize } from '../atoms/index.js'
import type { TextVariant } from '../atoms/text/variant.js'
import type { chipVariants } from './chip.util.js'

export type ChipVariant = (typeof chipVariants)[number]
export type ChipSize = 'small' | 'large'

export type ChipProps = {
  size: ChipSize
  /** The variant. Overriden by `backgroundColor` and `textColor` */
  variant?: ChipVariant
  dark?: boolean
  /** The background color. Overrides `variant` */
  backgroundColor?: string
  /** The text color. Overrides `variant` */
  textColor?: string

  icon?: CompoundIconName
  avatar?: ReactNode

  children: ReactNode
  className?: string
}

export const Chip = memo<ChipProps>(function Chip(props) {
  const { size, variant = 'neutral', dark, avatar, icon, children, className } = props

  const variantColors = colorsPerVariant[variant][dark ? 'dark' : 'light']
  const resolvedBackgroundColor = props.backgroundColor ?? variantColors.backgroundColor
  const resolvedTextColor = props.textColor ?? variantColors.textColor
  const iconColor = variantColors.iconColor
  const resolvedIconColor = props.textColor ?? iconColor
  const sizes = sizesPerChipSize[size]

  const setupAvatar = useMemo(
    () =>
      avatar || icon ? (
        <AvatarContext.Provider value={{ size: sizes.avatarSize }}>
          {avatar || <Avatar icon={icon} size={sizes.avatarSize} color={resolvedIconColor} />}
        </AvatarContext.Provider>
      ) : (
        <></>
      ),
    [avatar, icon, sizes, resolvedIconColor],
  )

  return (
    <ChipContainer className={className} resolvedBackgroundColor={resolvedBackgroundColor} sizes={sizes}>
      {avatar || icon ? setupAvatar : <></>}
      <TextWithEllipsis variant={sizes?.textVariant} resolvedTextColor={resolvedTextColor}>
        {children}
      </TextWithEllipsis>
    </ChipContainer>
  )
})
const ChipContainer = styled.div<{ resolvedBackgroundColor: string; sizes: Sizes }>`
  display: inline-flex;
  align-items: center;
  gap: ${spacing[30]};
  max-width: min-content;

  padding: ${spacing[20]} ${spacing[30]};

  background-color: ${({ resolvedBackgroundColor }) => resolvedBackgroundColor};
  border-radius: ${borderRadius[50]};
`

const TextWithEllipsis = styled(Text)<{ resolvedTextColor: string }>`
  color: ${({ resolvedTextColor }) => resolvedTextColor};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

type Colors = { backgroundColor: string; textColor: string; iconColor: string }

const colorsPerVariant: {
  [variant in ChipVariant]: { light: Colors; dark: Colors }
} = {
  neutral: {
    light: {
      backgroundColor: colorTokens['color-bg-neutral-inverse'],
      textColor: colorTokens['color-text-base-main'],
      iconColor: colorTokens['color-fg-base'],
    },
    dark: {
      backgroundColor: colorTokens['color-bg-neutral'],
      textColor: colorTokens['color-text-base-inverse'],
      iconColor: colorTokens['color-fg-base-inverse'],
    },
  },
  informal: {
    light: {
      backgroundColor: colorTokens['color-bg-info-inverse'],
      textColor: colorTokens['color-text-info-primary'],
      iconColor: colorTokens['color-fg-info'],
    },
    dark: {
      backgroundColor: colorTokens['color-bg-info-primary'],
      textColor: colorTokens['color-text-base-inverse'],
      iconColor: colorTokens['color-fg-info-primary'],
    },
  },
  success: {
    light: {
      backgroundColor: colorTokens['color-bg-success-inverse'],
      textColor: colorTokens['color-text-success-primary'],
      iconColor: colorTokens['color-fg-success'],
    },
    dark: {
      backgroundColor: colorTokens['color-bg-success-primary'],
      textColor: colorTokens['color-text-base-inverse'],
      iconColor: colorTokens['color-fg-success-primary'],
    },
  },
  warning: {
    light: {
      backgroundColor: colorTokens['color-bg-warning-inverse'],
      textColor: colorTokens['color-text-warning-primary'],
      iconColor: colorTokens['color-fg-warning'],
    },
    dark: {
      backgroundColor: colorTokens['color-bg-warning-primary'],
      textColor: colorTokens['color-text-base-inverse'],
      iconColor: colorTokens['color-fg-warning-primary'],
    },
  },
  danger: {
    light: {
      backgroundColor: colorTokens['color-bg-danger-inverse'],
      textColor: colorTokens['color-text-danger-primary'],
      iconColor: colorTokens['color-fg-danger'],
    },
    dark: {
      backgroundColor: colorTokens['color-bg-danger-primary'],
      textColor: colorTokens['color-text-base-inverse'],
      iconColor: colorTokens['color-fg-danger-primary'],
    },
  },
}

type Sizes = {
  height: string
  avatarSize: IconSize
  textVariant: TextVariant
}

const sizesPerChipSize: {
  [size in ChipSize]: Sizes
} = {
  small: { height: '24px', avatarSize: '10', textVariant: 'captionMedium' },
  large: { height: '28px', avatarSize: '30', textVariant: 'body2Medium' },
}
