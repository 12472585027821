import { formatYyyyMmDd, type CalendarDate } from '@orus.eu/calendar-date'
import { DOCUMENTATION_LANGUAGE, m, type FieldSpecification, type ValueDocumentation } from '@orus.eu/message'
import { success, type Result } from '@orus.eu/result'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionTrackingMessageFieldAdapter,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export class CalendarDateDimension<NAME extends string> extends AbstractDimension<NAME, CalendarDate> {
  override validateData(value: LooselyTypedValue): Result<CalendarDate, DimensionValidationProblem> {
    if (typeof value !== 'object' || value === null)
      return dimensionValidationFailure(`Field ${this.name} is not a CalendarDate`)
    if (!('year' in value))
      return dimensionValidationFailure(`Field ${this.name} is not a CalendarDate because property "year" is missing`)
    if (!('oneBasedMonth' in value))
      return dimensionValidationFailure(
        `Field ${this.name} is not a CalendarDate because property "oneBasedMonth" is missing`,
      )
    if (!('oneBasedDay' in value))
      return dimensionValidationFailure(
        `Field ${this.name} is not a CalendarDate because property "oneBasedDay" is missing`,
      )
    const { year, oneBasedMonth, oneBasedDay } = value
    if (typeof year !== 'number')
      return dimensionValidationFailure(
        `Field ${this.name} is not a CalendarDate because property "year" is not a number`,
      )
    if (!Number.isSafeInteger(year))
      return dimensionValidationFailure(
        `Field ${this.name} is not a CalendarDate because property "year" is not a safe integer`,
      )
    if (typeof oneBasedMonth !== 'number')
      return dimensionValidationFailure(
        `Field ${this.name} is not a CalendarDate because property "oneBasedMonth" is not a number`,
      )
    if (!Number.isSafeInteger(oneBasedMonth))
      return dimensionValidationFailure(
        `Field ${this.name} is not a CalendarDate because property "oneBasedMonth" is not a safe integer`,
      )
    if (typeof oneBasedDay !== 'number')
      return dimensionValidationFailure(
        `Field ${this.name} is not a CalendarDate because property "oneBasedDay" is not a number`,
      )
    if (!Number.isSafeInteger(oneBasedDay))
      return dimensionValidationFailure(
        `Field ${this.name} is not a CalendarDate because property "oneBasedDay" is not a safe integer`,
      )
    return success({ year, oneBasedMonth, oneBasedDay })
  }

  override readonly trackingMessageFieldAdapter: DimensionTrackingMessageFieldAdapter = {
    getTrackingFieldSpecification: (): FieldSpecification => {
      const valueDocumentation: ValueDocumentation<string> = {
        title: this.displayNames[DOCUMENTATION_LANGUAGE],
      }
      if (this.hints) {
        valueDocumentation.detailedExplanationMd = this.hints[DOCUMENTATION_LANGUAGE]
      }
      return m.nullable(m.string(valueDocumentation))
    },
    convertStateValueToTrackingValue: (stateValue: CalendarDate | null | undefined): string | null => {
      if (!stateValue) return null
      return formatYyyyMmDd(stateValue)
    },
  }
}
