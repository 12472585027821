import { css } from '@emotion/react'
import { createContext, memo, useContext, useMemo } from 'react'

import type { Color } from '../../../colors.js'
import { useUiContext, type UiContext } from '../../../hooks/use-screen-variant.js'
import { cssPropsPerScreenVariantPerTextVariant } from './css-props.js'
import type { TextVariant } from './variant.js'

export type TextProps = {
  /** The variant of the text. Defaults to `body1`. */
  variant?: TextVariant
  /** The screen variant. If not provided, defaults to detecting the viewport */
  screenVariant?: 'desktop' | 'mobile' | 'backoffice'
  /** The element type. If not provided, defaults to the semantic element (e.g. `h1` for `h1`), or `p` if not relevant (e.g. `body1`) */
  element?: ElementType
  color?: Color
  backgroundColor?: Color
  children: React.ReactNode
  htmlFor?: string
  className?: string
  /** If provided, the text will stay on 1 line, having ellipsis if overflowing */
  noWrap?: boolean
}

/**
 * The Text component represents a text in a given variant.
 *
 * You can think of it as the Pharaoh replacement for the MUI's `Typography` component.
 */
export const Text: React.FunctionComponent<TextProps> = memo(function Text(props) {
  const { variant, screenVariant, element, children, htmlFor, className, color, noWrap } = props

  const textContext = useContext(TextContext)

  const resolvedVariant = variant ?? textContext?.variant ?? 'body1'
  const detectedScreenVariant = useUiContext()
  const resolvedScreenVariant = screenVariant ?? textContext.screenVariant ?? detectedScreenVariant
  const resolvedColor = color ?? textContext.color ?? undefined
  const cssProps = cssPropsPerScreenVariantPerTextVariant[resolvedVariant][resolvedScreenVariant]
  const ResolvedElement = element ?? elementPerVariant[resolvedVariant]
  const css = useMemo(() => {
    const { fontSize, fontWeight, fontFamily, lineHeight, textTransform, letterSpacing } = cssProps

    return {
      fontSize,
      fontWeight,
      fontFamily,
      lineHeight,
      textTransform,
      letterSpacing,
      margin: 0,
      padding: 0,
      color: resolvedColor,
      sup: {
        verticalAlign: 'text-top',
      },
      ...(noWrap ? { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } : {}),
    }
  }, [cssProps, resolvedColor, noWrap])

  return (
    <ResolvedElement css={css} htmlFor={htmlFor} className={className}>
      {children}
    </ResolvedElement>
  )
})

type TextContainerProps = {
  /** The variant of the text. Defaults to `body1` */
  variant?: TextVariant
  color?: Color
  /** The screen type. If not provided, defaults to detecting the viewport */
  screenVariant?: UiContext

  children: React.ReactNode
  className?: string
}

/**
 * This component is used to work around the fact that CSS does not allow to represent
 * "paragraph spacing". By using this component, all its children will be spaced by the
 * variant-specific spacing.
 *
 * Note that this component will set the variant for all its Text children, so you don't need
 * to specify the variant on every Text.
 */
export const TextContainer: React.FunctionComponent<TextContainerProps> = memo(function TextContainer(props) {
  const { variant = 'body1', color, screenVariant, children, className } = props

  const detectedScreenVariant = useUiContext()
  const resolvedScreenVariant = screenVariant ?? detectedScreenVariant
  const { paragraphSpacing } = cssPropsPerScreenVariantPerTextVariant[variant][resolvedScreenVariant]

  const textContextValue = useMemo(
    () => ({ variant, color, screenType: resolvedScreenVariant }),
    [variant, color, resolvedScreenVariant],
  )

  return (
    <div
      css={css`
        & > *:not(:last-child) {
          margin-bottom: ${paragraphSpacing};
        }
      `}
      className={className}
    >
      <TextContext.Provider value={textContextValue}>{children}</TextContext.Provider>
    </div>
  )
})

type ElementType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'div' | 'p' | 'label'

const elementPerVariant: { [typoVariant in TextVariant]: ElementType } = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'p',
  subtitle2: 'p',
  body1: 'p',
  body1Medium: 'p',
  body1Semibold: 'p',
  body2: 'p',
  body2Medium: 'p',
  body2Semibold: 'p',
  caption: 'p',
  captionMedium: 'p',
  button: 'span',
  input: 'span',
}

const TextContext = createContext<Partial<Pick<TextProps, 'variant' | 'color' | 'screenVariant'>>>({})
