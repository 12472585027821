import { TechnicalError } from '@orus.eu/error'
import { z } from 'zod'

export const operatingZones = ['fr', 'es'] as const

export function isOperatingZone(value: string): value is OperatingZone {
  const operatingZonesStrings: readonly string[] = operatingZones
  return operatingZonesStrings.includes(value)
}

export function assertIsOperatingZone(value: string): asserts value is OperatingZone {
  if (!isOperatingZone(value)) {
    throw new TechnicalError('Invalid operatingZone', { context: { value } })
  }
}

export const operatingZoneSchema = z.enum(operatingZones)

/**
 * Enum of the geographical zones in which Orus operates
 */
export type OperatingZone = z.infer<typeof operatingZoneSchema>
