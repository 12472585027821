import { nbsp } from '@orus.eu/char'
import type { MutaGuaranteeGroupType, PolicyGuaranteeVisualPriority } from '../quote-offer-information-dimension.js'

export const mutaGuaranteeProductInformation = {
  MedecinGeneraliste: {
    name: 'Médecin généraliste ou spécialiste',
    shortText: 'Consultation de médecin ou acte de chirurgie, échographie et imagerie',
    fullText: 'Nous couvrons les consultation généraliste ou acte de chirurgie, échographie et imagerie.',
    example: `La Sécurité sociale ne prend en charge que 70% du tarif de base des médecins généralistes et spécialistes. Orus vous rembourse le reste du tarif de base et, selon la formule choisie, tout ou partie des dépassements d'honoraires.`,
    priority: 'P1',
  },
  Paramedicaux: {
    name: 'Paramédical',
    shortText: 'Infirmiers, kinésithérapeutes, orthophonistes et orthoptistes',
    fullText: 'Nous couvrons les auxiliaires médicaux, Infirmiers, aide-soignants, puériculteurs, rééducation.',
    example: `La Sécurité sociale ne rembourse pas la totalité des soins paramédicaux (en général, seul 80% du tarif de base est remboursé). Orus vous rembourse le reste du tarif de base.`,
    priority: 'P1',
  },
  AnalysesExamsLaboratoires: {
    name: 'Analyses et examens de laboratoires',
    shortText: 'Examens biochimiques, bactériologiques, examens de sang',
    fullText: 'Nous couvrons les examens biochimiques, bactériologiques, examens de sang.',
    example: `La Sécurité sociale ne rembourse pas la totalité des examens (en général, seul 60-70% du prix vous est remboursé). Orus vous rembourse le reste.`,
    priority: 'P1',
  },
  Medicaments: {
    name: 'Médicaments',
    shortText: 'Médicaments pris en charge',
    fullText: 'Nous couvrons les médicaments pris en charge par la Sécurité sociale.',
    example: `La Sécurité sociale ne rembourse pas la totalité du prix des médicaments (en général, seul 30 ou 65% du prix vous est remboursé, selon le type de médicament). Orus vous rembourse le reste.`,
    priority: 'P1',
  },
  Transport: {
    name: 'Transport',
    shortText: 'Transport vers ou depuis votre lieu de soin (hors cures thermales)',
    fullText: 'Nous couvrons les transports soit vers ou depuis votre lieu de soin (sauf cures thermales).',
    example: `La Sécurité sociale ne rembourse pas tous les frais de transports (en taxi conventionné ou en ambulance) liés à des soins ou à des examents. En général, seul 55% du prix vous est remboursé. Orus prend en charge le reste.`,
    priority: 'P1',
  },
  MedecineNaturelle: {
    name: 'Médecine naturelle',
    shortText: 'Forfait annuel pour les consultations d’ostéopathie, acupuncture, podologie ou chiropraxie.',
    fullText: 'Forfait annuel pour les consultations d’ostéopathie, acupuncture, podologie ou chiropraxie.',
    example: `La médecine douce n'est généralement pas remboursée par la Sécurité sociale. Selon votre formule, Orus vous rembourse une partie de ces soins. `,
    priority: 'P1',
  },
  ActesDePrevention: {
    name: 'Actes de prévention',
    shortText: 'Actes préventifs, comme les vaccinations, les dépistages et les bilans du langage oral',
    fullText:
      'Actes préventifs, comme les vaccinations, les dépistages et les bilans du langage oral. Les cures thermales ne sont pas remboursées.',
    example: `La Sécurité sociale ne prend pas en charge la totalité des frais de prévention. Par exemple, pour la plupart des vaccins, seul 60-70% du prix est remboursé.
\
    Orus vous rembourse le reste du prix de vos actes de prévention.
\
    Cas particulier : les frottis cervico-vaginaux tous les 3 ans, les dépistages du cancer du sein pour les femmes de 50-74 ans et les vaccins obligatoires sont remboursés à 100% par la Sécurité sociale.`,
    priority: 'P1',
  },
  PetitAppareillage: {
    name: 'Petit appareillage',
    shortText:
      'Attelles, chevillières, chaussures thérapeutiques, bas de contention, ceinture de soutien lombaire, etc.',
    fullText:
      'Nous couvons les frais pour des attelles, chevillière, chaussures thérapeutiques, bas de contention, ceintures de soutien lombaire, etc.',
    example: `La Sécurité sociale ne prend en charge que 60% du petit appareillage médical. La mutuelle Orus vous rembourse le reste du prix.`,
    priority: 'P1',
  },
  ProthesesEtAppareillages: {
    name: 'Prothèses et appareillages',
    shortText: 'Fauteuils roulants, prothèses et appareillages autres que dentaires et auditifs',
    fullText:
      'Nous couvrons les frais pour les fauteuils roulants, prothèses et appareillages autres que dentaires et auditif.',
    example: `La Sécurité sociale ne prend généralement en charge que 50% du tarif de base des fauteuils roulants et prothèses. Orus prend en charge le reste du tarif de base.`,
    priority: 'P1',
  },
  SoinsDentaires: {
    name: 'Soins dentaires',
    shortText: 'Consultations et soins dentaires, dont inlays, onlays et scellement des sillons',
    fullText: 'Nous couvons les consultations et soins dentaires, dont Inlays, onlays, scellement des sillons.',
    example: `La Sécurité sociale ne prend généralement en charge que 50% du tarif de base des soins dentaires.
    \
    Orus prend en charge le reste du tarif de base et, selon la formule choisie, tout ou partie des dépassements d'honoraires.`,
    priority: 'P1',
  },
  Orthodontie: {
    name: 'Orthodontie',
    shortText: 'Pour les moins de 16 ans uniquement',
    fullText: `Pour les moins de 16 ans uniquement. (Consulter la garantie «${nbsp}Soins dentaires non pris en charge${nbsp}» pour les autres cas).`,
    example: `Pour les moins de 16 ans, la Sécurité sociale rembourse à 60% le tarif de base des actes de moins de 120€ et à 100% le tarif de base des actes supérieurs à 120€,.
    \
    Cependant, les honoraires des traitements d'orthodontie sont libres et généralement très supérieurs au tarif de base.
    \
    Pendant la 1e année de votre contrat, Orus rembourse le reste du tarif de base. Selon votre formule, nous remboursons également une partie des dépassements d'honoraires pendant les 2e et 3e années de votre contrat.`,
    priority: 'P1',
  },
  ProthesesDentaires: {
    name: 'Prothèses dentaires',
    shortText: 'Prothèses dentaires et couronnes, dont inlay-core.',
    fullText: 'Nous couvons les prothèses dentaires et couronnes, Inlays-core et implantologie pris en charge.',
    example: `La Sécurité sociale ne rembourse qu'une partie des frais liés aux interventions dentaires (par exemple, 70% du tarif de base pour une couronne dentaire).
    \
    Pendant la 1e année de votre contrat, Orus rembourse le reste du tarif de base. Selon votre formule, nous remboursons également une partie des dépassements d'honoraires pendant les 2e et 3e années de votre contrat.`,
    priority: 'P1',
  },
  DentaireNonPrisEnCharge: {
    name: 'Dentaire non pris en charge',
    shortText:
      'Forfait annuel pour l’orthodontie pour adulte et les soins dentaires non pris en charge par la Sécurité sociale',
    fullText: 'Forfait annuel pour les implants non pris en charge, orthodontie pour adulte.',
    example: `Certains soins dentaires ne sont pas pris en charge pas la Sécurité sociale, par exemple l'orthodontie pour adultes ou les soins parodontaires. Selon votre formule, Orus rembourse une partie de ces soins.`,
    priority: 'P1',
  },
  MontureVerresSimples: {
    name: 'Montures et verres simples',
    shortText: 'Monture et 2 verres simples',
    fullText:
      'Prise en charge d’une monture et de 2 verres simples. Quelle que soit la formule, le remboursement de la monture ne peut dépasser 100 €.',
    example: `Les lunettes et verres sont en général très mal pris en charge par la Sécurité sociale.
\
    Si vous choisissez des lunettes «${nbsp}100% santé${nbsp}», Orus vous rembourse intégralement. Il s'agit d'une sélection de 17 modèles de lunettes proposés par tous les opticiens et sans reste à charge.
\
    Si vous choisissez des lunettes hors «${nbsp}100% santé${nbsp}», consultez le plafond d'indemnisation ci-dessus pour connaître votre remboursement.`,
    priority: 'P1',
  },
  MontureVerresComplexes: {
    name: 'Montures et verres complexes',
    shortText: 'Monture et 2 verres complexes ou très complexes',
    fullText:
      'Prise en charge d’une monture et de 2 verres complexes ou très complexes. Quelle que soit la formule, le remboursement de la monture ne peut dépasser 100 €.',
    example: `Les lunettes et verres sont en général très mal pris en charge par la Sécurité sociale.
\
    Si vous choisissez des lunettes «${nbsp}100% santé${nbsp}», Orus vous rembourse intégralement. Il s'agit d'une sélection de 17 modèles de lunettes proposés par tous les opticiens et sans reste à charge.
\
    Si vous choisissez des lunettes hors «${nbsp}100% santé${nbsp}», consultez le plafond d'indemnisation ci-dessus pour connaître votre remboursement.`,
    priority: 'P1',
  },
  Lentilles: {
    name: 'Lentilles',
    shortText: 'Lentilles prescrites, qu’elles soient où non prises en charge par la Sécurité sociale',
    fullText:
      'Forfait annuel pour lentilles prescrites, qu’elles soient prises en charge par la Sécurité sociale ou non.',
    example: `Les lentilles sont mal prises en charge par la Sécurité sociale (60% d'un tarif de base de 39,98€ par an et par œil). Selon votre formule, Orus rembourse le reste de ce tarif de base ou un forfait annuel`,
    priority: 'P1',
  },
  AppareillageEtProthesesAuditives: {
    name: 'Appareillages et prothèses auditives',
    shortText: 'Appareillages et prothèses auditives prises en charge',
    fullText: 'Nous couvrons les appareillages et prothèses auditives prises en charge.',
    example: `La Sécurité sociale ne rembourse que 60% d'un forfait de 400€ par an et par oreille. Orus rembourse le reste de ce tarif.`,
    priority: 'P1',
  },
  AidesAuditives: {
    name: 'Aides auditives non prises en charge par la Sécurité sociale',
    shortText: 'Forfait annuel appareil auditif',
    fullText: 'Forfait annuel pour les aides auditives non prises en charge par la Sécurité sociale',
    example: `Certaines aides auditives ne sont pas prises en charge par la Sécurité sociale. Selon la formule choisie, Orus rembourse vos aides auditives sur la base d’un forfait annuel.`,
    priority: 'P1',
  },
  FraisDeSejour: {
    name: 'Frais de séjour',
    shortText: 'En cas d’acte chirurgical ou de séjour en maternité ou unité spéciale',
    fullText:
      'Nous couvrons les frais de séjour dans un établissement conventionné pour un acte chirurgical, en maternité ou dans une unité spéciale.',
    example: `La Sécurité sociale ne prend en charge que 80% du tarif de base pour des frais de séjours à l'hôpital ou en clinique conventionnée. Orus prend en charge le reste du prix.`,
    priority: 'P1',
  },
  HonorairesPraticiens: {
    name: 'Honoraires de praticiens',
    shortText: 'Praticiens adhérents à un dispositif de pratique tarifaire maîtrisée',
    fullText:
      'Nous prenons en charge les coûts de praticiens adhérents à un dispositif de pratiques tarifaires maîtrisées.',
    example: `Les honoraires des praticiens ne sont que partiellement remboursés par la Sécurité sociale. Orus vous permet d'être remboursé(e) à 100% du tarif de base. Selon la formule choisie, Orus prend également tout ou partie des dépassements d'honoraires de votre praticien.
    \
    Vous serez toujours mieux remboursé(e) si votre praticien exerce en secteur 1 ou en secteur 2 et adhère à un dipositif de pratique tarifaire maîtrisée (Optam). Si vous choisissez un médecin en secteur 3, vous ne serez quasiment pas remboursé(e) par la Sécurité sociale et moins bien remboursé(e) par Orus.`,
    priority: 'P1',
  },
  HonorairesEtablissements: {
    name: 'Honoraires des établissements non conventionnés',
    shortText: 'Praticiens non adhérents à un dispositif de pratique tarifaire maîtrisée',
    fullText: "Nous couvrons les actes de chirurgie, acte d'anesthésie, dépassement d'honoraires.",
    example: `Les honoraires des praticiens n'adhérent pas à un dispositif de pratique tarifaire maîtrisée (Optam) sont peu remboursés par la Sécurité sociale. Orus vous permet d'être remboursé(e) à 100% du tarif de base. Selon la formule choisie, Orus prend également tout ou partie des dépassements d'honoraires de votre praticien.
    \
    Vous serez toujours mieux remboursé(e) si votre praticien exerce en secteur 1 ou en secteur 2 et adhère à un dipositif de pratique tarifaire maîtrisée (Optam). Si vous choisissez un médecin en secteur 3, vous ne serez quasiment pas remboursé(e) par la Sécurité sociale et moins bien remboursé(e) par Orus.`,
    priority: 'P1',
  },
  ChambreParticuliere: {
    name: 'Chambre particulière',
    shortText: 'La chambre individuelle est considérée comme un soin de confort',
    fullText: 'La chambre individuelle est considérée comme un soin de confort.',
    example: `La Sécurité sociale rembourse 80% des frais d'hospitalisation, mais ne rembourse pas les chambres individuelles, considérées comme un soin de confort. Selon la formule Orus que vous avez choisie, Orus peut rembourser votre chambre individuelle, sur la base d'un forfait journalier.`,
    priority: 'P1',
  },
  SejourAccompagnant: {
    name: 'Séjour accompagnant',
    shortText: 'Lit et repas de l’accompagnant (à partir de la 2e année)',
    fullText:
      "Nous prenons en charge les frais liés à la présence d'un accompagnant lors de votre séjour à l'hôpital, ce qui inclut la mise à disposition d'un lit supplémentaire ainsi que les repas de l'accompagnant, à partir de la 2e année de votre contrat Orus.",
    example: `La Sécurité sociale ne rembourse pas les frais liés à la présence d'un accompagnant. Selon votre formule, nous remboursons une partie de ces frais, sur la base d'un forfait journalier (à partir de la 2e année de votre contrat Orus).`,
    priority: 'P1',
  },
  ConfortHospitalier: {
    name: 'Confort hospitalier',
    shortText: 'Internet, télévision, téléphone (à partir de la 2e année)',
    fullText:
      'Nous couvrons l’accès à une télévision, connexion internet et téléphone, maximum 15 jours par an, à partir de la 2e année de votre contrat Orus.',
    example: `Vous êtes hospitalisé pour une intervention chirurgicale importante. Pendant votre séjour à l'hôpital, vous réalisez à quel point il est important d'avoir accès à des divertissements tels que la télévision et Internet pour rester en contact avec vos proches.
\
Ces frais ne sont pas couverts par la Sécurité sociale, mais Orus peut en couvrir une partie, selon la formule que vous avez choisie.`,
    priority: 'P1',
  },
  HospitalisationDomicile: {
    name: 'Hospitalisation à domicile',
    shortText: 'Soins médicaux et paramédicaux dans le cadre d’une hospitalisation à domicile (HAD)',
    fullText: 'Nous couvrons vos soins médicaux et paramédicaux importants à votre domicile (HAD).',
    example: `Comme toutes les hospitalisation, les hospitalisations à domicile (HAD) sont prises en charge à 80% par la Sécurité sociale. Orus prend en charge le reste de votre dépense.`,
    priority: 'P1',
  },
  TeleconsultationMedicale: {
    name: 'Téléconsultation médicale',
    shortText:
      'Téléconsultation et téléprescription médicamenteuse, réalisée par un médecin par téléphone ou en ligne disponible 7j/7 et 24h/24.',
    fullText:
      'Téléconsultation et téléprescription médicamenteuse, réalisée par un médecin par téléphone ou en ligne disponible 7j/7 et 24h/24.',
    example: `Votre contrat Orus vous donne le droit à 10 téléconsultations gratuites par an.`,
    priority: 'P1',
  },
  CoachingBienEtre: {
    name: 'Coaching bien-être',
    shortText: 'Séance en ligne avec un coach spécialisé et accès à du contenu en illimité depuis l’espace en ligne',
    fullText:
      'Nous proposons des séances en ligne avec un coach spécialisé et accès à du contenu en illimité depuis l’espace en ligne',
    example: `Vous êtes déterminé à reprendre un mode de vie sain, en intégrant une routine sportive, une alimentation équilibrée et une meilleure qualité de sommeil dans votre quotidien. Vous comprenez l'importance de disposer des ressources adéquates pour atteindre cet objectif.
\
Cette garantie vous donne accès à une gamme complète de ressources pour vous soutenir dans votre démarche. Vous avez accès à un portail riche en contenus, comprenant des exercices de respiration, des plannings de séances de sport et un bilan d'évaluation santé. De plus, cette garantie vous offre la possibilité de consulter des professionnels spécialisés tels qu'un entraîneur sportif, un diététicien ou un psychologue.
\
Le coût de ces consultations est pris en charge par votre mutuelle Orus.`,
    priority: 'P1',
  },
  AccompagnementPsychologique: {
    name: 'Coaching bien-être psychologique',
    shortText: 'Séance en ligne avec psychologue partenaire',
    fullText: 'Nous proposons un accès à un portail digital et 4 entretiens à distance avec un psychologue.',
    example: `Vous êtes très occupé, et vous ressentez depuis un certain temps un niveau de stress élevé lié à votre travail et à vos responsabilités familiales.
\
Vous pouvez retrouver un psychologue en ligne pour vous aider à gérer votre stress et à améliorer votre bien-être émotionnel.`,
    priority: 'P1',
  },
  AideDomicile: {
    name: 'Aide à domicile',
    shortText: '15 services dont auxiliaire de vie, aide-ménagère, garde d’enfants, personnes à charge et des animaux',
    fullText:
      'Un ensemble de 15 services disponibles, dont auxiliaire de vie, aide-ménagère, garde d’enfants, personnes à charge et des animaux.',
    example: `Après votre hospitalisation, vous regagnez votre domicile, mais vous vous trouvez dans l'incapacité de réaliser les tâches quotidiennes en raison de votre immobilisation. Pour faciliter votre rétablissement et soulager votre charge de travail, vous avez la possibilité de faire appel à une gamme variée de services d'aide à domicile.
\
Vous pouvez bénéficier d'une gamme étendue de près de 15 services. Cela englobe des prestations telles que la livraison de courses, l'aide ménagère, la garde d'enfants à domicile, et bien d'autres.
\
En plus de vous proposer des prestataires, nous prenons en charge les frais liés à ces services à domicile.`,
    priority: 'P1',
  },
  TransportTaxi: {
    name: 'Transport taxi',
    shortText:
      'Retour et transfert au domicile, transport des enfants dont vers activité extra-scolaires, livraisons des courses.',
    fullText:
      'Nous prenons en charge les frais de retour et transfert au domicile, transport des enfants dont vers activité extra-scolaires, livraisons des courses.',
    example: `Après votre hospitalisation, vous regagnez votre domicile, mais vous vous trouvez dans l'incapacité de réaliser les tâches quotidiennes en raison de votre immobilisation.
\
Pour faciliter votre rétablissement et soulager votre charge de travail, vous avez la possibilité de faire appel à une gamme variée de services d'aide à domicile.
\
Cela englobe des prestations telles que la conduite en taxi à l'école ou aux activités extra-scolaires des enfants, le transfert d'un de vos proche à votre domicile, la livraison de courses…`,
    priority: 'P1',
  },
} satisfies Record<
  MutaGuaranteeGroupType,
  Record<'name' | 'shortText' | 'fullText' | 'example', string> | { priority: PolicyGuaranteeVisualPriority }
>
