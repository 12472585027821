import styled from '@emotion/styled'
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  type Placement,
} from '@floating-ui/react'
import React, { memo, useState, type ReactElement } from 'react'
import type { RowContainerProps } from '../../components/rows'
import { shadow, spacing } from '../../foundation'

type DropdownProps = {
  children: ReactElement<RowContainerProps>
  trigger: ReactElement
  placement?: Placement
  className?: string
}

export const Dropdown = memo<DropdownProps>(function Dropdown(props) {
  const { children, trigger, placement, className } = props

  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: placement ?? 'bottom-start',
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      flip({
        fallbackAxisSideDirection: 'start',
        fallbackPlacements: ['bottom-end'],
        fallbackStrategy: 'initialPlacement',
      }),
      shift(),
      offset(GAP),
    ],
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss])

  return (
    <>
      {React.cloneElement(trigger, { ref: refs.setReference, ...getReferenceProps() })}
      {isOpen ? (
        <DropdownContainer
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          className={className}
          onBlur={() => setIsOpen(false)}
        >
          {children}
        </DropdownContainer>
      ) : null}
    </>
  )
})

const GAP = 8

const DropdownContainer = styled.div`
  position: absolute;
  z-index: 1;

  border-radius: ${spacing[30]};

  box-shadow: ${shadow.bottom[30]};
`
