import { memo } from 'react'
import { useTranslate } from '../../../../localization'
import { ValidatedTextFieldFormField } from '../../../molecules'
import { IbanFormInputContainer, STRING_MAPPER } from './utils'

export type SepaCustomerDataInputsParams = {
  firstNameValue: string
  onFirstNameChange: ((firstName: string | undefined) => void) | undefined
  lastNameValue: string
  onLastNameChange: ((lastName: string | undefined) => void) | undefined
  emailValue: string
  onEmailChange: ((email: string | undefined) => void) | undefined
  addressValue: string
  onAddressChange: ((address: string | undefined) => void) | undefined
  postalCodeValue: string
  onPostalCodeChange: ((postalCode: string | undefined) => void) | undefined
  cityValue: string
  onCityChange: ((city: string | undefined) => void) | undefined
}

export const SepaCustomerDataInputs = memo<SepaCustomerDataInputsParams>(function SepaCustomerDataInputs({
  addressValue,
  emailValue,
  firstNameValue,
  lastNameValue,
  onAddressChange,
  onEmailChange,
  onFirstNameChange,
  onLastNameChange,
  onPostalCodeChange,
  postalCodeValue,
  cityValue,
  onCityChange,
}) {
  const translate = useTranslate()
  return (
    <>
      <IbanFormInputContainer>
        <ValidatedTextFieldFormField
          size="large"
          label={translate('dimension_name_first_name')}
          value={firstNameValue}
          placeholder={translate('dimension_placeholder_first_name')}
          mapper={STRING_MAPPER}
          onChange={onFirstNameChange}
        />
        <ValidatedTextFieldFormField
          size="large"
          label={translate('dimension_name_last_name')}
          value={lastNameValue}
          placeholder={translate('dimension_placeholder_last_name')}
          mapper={STRING_MAPPER}
          onChange={onLastNameChange}
        />
      </IbanFormInputContainer>

      <ValidatedTextFieldFormField
        size="large"
        label={translate('dimension_name_email')}
        value={emailValue}
        placeholder={translate('dimension_placeholder_email')}
        mapper={STRING_MAPPER}
        onChange={onEmailChange}
      />

      <ValidatedTextFieldFormField
        size="large"
        label={translate('address_street')}
        value={addressValue}
        placeholder={translate('address_street_placeholder')}
        mapper={STRING_MAPPER}
        onChange={onAddressChange}
      />

      <IbanFormInputContainer>
        <ValidatedTextFieldFormField
          size="large"
          label={translate('address_postcode')}
          value={postalCodeValue}
          placeholder={translate('address_postcode_placeholder')}
          mapper={STRING_MAPPER}
          onChange={onPostalCodeChange}
        />

        <ValidatedTextFieldFormField
          size="large"
          label={translate('address_city')}
          value={cityValue}
          placeholder={translate('address_city_placeholder')}
          mapper={STRING_MAPPER}
          onChange={onCityChange}
        />
      </IbanFormInputContainer>
    </>
  )
})
