// eslint-disable-next-line no-restricted-imports -- We can rely on MUI for pharaoh implementation details
import { useMediaQuery } from '@mui/material'

export type ScreenType = 'mobile' | 'desktop'

const mobileDetectionCondition = '(max-width:1023px)'

const compactBackofficeDetectionCondition = '(max-width:768px)'
const compactContainerDetectionCondition = '(max-width:460px)'

/**
 * @deprecated Do not use `mobileMediaQuery`. We are mobile first, so let
 * the "default css" be the css that works for mobile, and add a special
 * case for the desktop using {@link desktopMediaQuery}.
 *
 * @example
 *
 * // Correct because it's mobile-first
 * align-items: stretch;
 * flex-flow: column wrap;
 * ${desktopMediaQuery} {
 *   align-items: center;
 *   flex-flow: row wrap;
 * }
 *
 * // Incorrect it's desktop first
 * align-items: center;
 * flex-flow: row wrap;
 * ${mobileMediaQuery} {
 *   align-items: stretch;
 *   flex-flow: column wrap;
 * }
 *
 * // Incorrect because there is no default
 * ${desktopMediaQuery} {
 *   align-items: center;
 *   flex-flow: row wrap;
 * }
 * ${mobileMediaQuery} {
 *   align-items: stretch;
 *   flex-flow: column wrap;
 * }
 *
 */
export const mobileMediaQuery = `@media ${mobileDetectionCondition}`
export const desktopMediaQuery = `@media not ${mobileDetectionCondition}`

/**
 * @deprecated Use {@link useUiContext} instead
 */
export function useScreenType(): ScreenType {
  return useMediaQuery(mobileMediaQuery) ? 'mobile' : 'desktop'
}

export const largeBackOfficeMediaQuery = `@media not ${compactBackofficeDetectionCondition}`
export const largeContainerQuery = `@container not ${compactContainerDetectionCondition}`
