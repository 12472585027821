import { useOpenChat, useTranslate } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import { GenericProblemMessage } from './generic-problem-message'

export const NotFound: FunctionComponent<{ message?: string }> = memo(function NotFound({ message }) {
  const translate = useTranslate()
  const openChat = useOpenChat()

  return (
    <GenericProblemMessage
      title={translate('problem_not_found_title')}
      buttonText={translate('contact_customer_support')}
      onButtonClick={openChat}
      trackingId="contact_button"
      principalMessage={translate('problem_not_found_principal_message')}
      firstSubText={message ?? translate('problem_not_found_first_subtext')}
      secondSubText={translate('problem_not_found_second_subtext')}
    />
  )
})
