import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { createLink } from '@tanstack/react-router'
import { forwardRef, memo } from 'react'
import anneHerlanHelp from '../../assets/anne-herlan-help.png'
import { Icon, Text } from '../../components'
import { borderRadius, borderStroke, colorTokens, shadow, spacing } from '../../foundation'
import { useTranslate } from '../../localization'

type NavbarAdvisorState = 'default' | 'hover'

export type NavbarAdvisorProps = {
  ariaLabel?: string
  onClick?: () => void
  className?: string

  'data-testid'?: string
}

export const NavbarAdvisor = createLink(
  memo(
    forwardRef<HTMLButtonElement, NavbarAdvisorProps>(function NavbarLine(props: NavbarAdvisorProps, ref) {
      const { ariaLabel, 'data-testid': dataTestId, onClick, ...otherProps } = props

      const translate = useTranslate()

      return (
        <StyledNavbarAdvisor
          ref={ref}
          buttonStylePerState={buttonStylePerState}
          aria-label={ariaLabel}
          data-testid={dataTestId}
          onClick={onClick}
          {...otherProps}
        >
          <img src={anneHerlanHelp} alt="help" width="48px" height="48px" />

          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: start;
              max-width: 320px;
            `}
          >
            <Text variant="body2Medium" color={colorTokens['color-text-base-main']}>
              {translate('question_cta_title')}
            </Text>
            <Text
              css={css`
                text-align: left;
                text-wrap: wrap;
              `}
              color={colorTokens['color-text-base-basic']}
              variant="caption"
            >
              {translate('question_cta_subtitle')}
            </Text>
          </div>

          <div
            css={css`
              margin-left: auto;
            `}
          >
            <Icon icon="arrow-right-regular" size="30" />
          </div>
        </StyledNavbarAdvisor>
      )
    }),
  ),
)

const StyledNavbarAdvisor = styled.button<{ buttonStylePerState: ButtonStylesPerState }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: ${spacing[50]};
  padding: ${spacing[50]};

  border-radius: ${borderRadius[40]};
  border: ${borderStroke[20]} solid ${colorTokens['color-stroke-base']};
  background: ${colorTokens['color-bg-base-normal']};

  box-shadow: ${shadow.bottom[10]};

  outline: none;
  text-decoration: none;
  cursor: pointer;

  transition: background-color 0.25s ease-in-out;

  ${({ buttonStylePerState }) => buttonStylePerState.default}

  &:hover {
    ${({ buttonStylePerState }) => buttonStylePerState.hover}
  }
`

type ButtonStylesPerState = Record<NavbarAdvisorState, { backgroundColor: string; border: string; boxShadow: string }>

const buttonStylePerState: ButtonStylesPerState = {
  default: {
    backgroundColor: colorTokens['color-bg-base-normal'],
    border: `${borderStroke['20']} solid ${colorTokens['color-stroke-base']}`,
    boxShadow: shadow.bottom[10],
  },
  hover: {
    backgroundColor: colorTokens['color-bg-base-hover'],
    border: `${borderStroke['20']} solid ${colorTokens['color-stroke-base-hover']}`,
    boxShadow: shadow.bottom[20],
  },
}
