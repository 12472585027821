import { isEmailValid } from '@orus.eu/email'
import { DOCUMENTATION_LANGUAGE, m, type FieldSpecification, type ValueDocumentation } from '@orus.eu/message'
import { success, type Result } from '@orus.eu/result'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionTrackingMessageFieldAdapter,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export class EmailDimension<NAME extends string> extends AbstractDimension<NAME, string> {
  override validateData(value: LooselyTypedValue): Result<string, DimensionValidationProblem> {
    if (!(typeof value === 'string')) return dimensionValidationFailure(`Field ${this.name} is not a string`)

    const email = value.trim().toLocaleLowerCase()
    return isEmailValid(email) ? success(email) : dimensionValidationFailure(`Field ${this.name} is not a valid email`)
  }

  override readonly trackingMessageFieldAdapter: DimensionTrackingMessageFieldAdapter = {
    getTrackingFieldSpecification: (): FieldSpecification => {
      const valueDocumentation: ValueDocumentation<string> = {
        title: this.displayNames[DOCUMENTATION_LANGUAGE],
      }
      if (this.hints) {
        valueDocumentation.detailedExplanationMd = this.hints[DOCUMENTATION_LANGUAGE]
      }
      return m.nullable(m.string(valueDocumentation))
    },
    convertStateValueToTrackingValue: (stateValue: string | null | undefined): string | null => {
      return stateValue ?? null
    },
  }
}
