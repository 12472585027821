import { nbsp } from '@orus.eu/char'
import { AbstractDimension } from '../abstract-dimension.js'
import type { MutaGuaranteeLimit } from './guarantees/muta-guarantees-limit.js'
import type { MutaGuarantees } from './guarantees/muta-guarantees.js'

export class MutaGuaranteesDimension<Name extends string, Guarantees extends MutaGuarantees> extends AbstractDimension<
  Name,
  Guarantees
> {}

export const mutaGuaranteesDimension = new MutaGuaranteesDimension({
  name: 'mutaGuarantees',
  displayValues: { name: 'Garanties mutuelles' },
  tags: ['MUTA'],
} as const)

export function formatGuaranteeLimit(guaranteeLimit: MutaGuaranteeLimit): string {
  switch (guaranteeLimit.type) {
    case 'yes':
      return 'Oui'
    case 'notReimbursed':
      return 'Non remboursé'
    case 'percentage':
      return `${guaranteeLimit.percentage}${nbsp}%${nbsp}BRSS`
    case 'amount':
      return `${guaranteeLimit.amount}${nbsp}€`
    case 'realCost':
      return 'Frais réels'
    case 'percentage-and-amount':
      return `${guaranteeLimit.percentage}${nbsp}%${nbsp}BRSS${nbsp}+${nbsp}${guaranteeLimit.amount}${nbsp}€`
    case 'sessionPerInsured':
      return `${guaranteeLimit.session} par an et par bénéficiaire`
    case 'session':
      return `${guaranteeLimit.session} séances`
  }
}
