import { StrictMode, type ReactNode } from 'react'
import 'simplebar-react/dist/simplebar.min.css'

import { TechnicalError } from '@orus.eu/error'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import { trpcReact, trpcReactClient } from './client'

import { App } from './app'
import { DevTools } from './DevTools'

const container = document.getElementById('react-root')
if (!container) {
  throw new TechnicalError('Element with id "react-root" not found')
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 0,
    },
    mutations: {
      gcTime: 0,
    },
  },
})

const root = createRoot(container)
const rootReactNote: ReactNode = (
  <StrictMode>
    <trpcReact.Provider client={trpcReactClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <App />
        <DevTools />
      </QueryClientProvider>
    </trpcReact.Provider>
  </StrictMode>
)

root.render(rootReactNote)

import('./lib/sentry-integration')
  .then(({ ensureSentryIsInitialized }) => {
    ensureSentryIsInitialized()
  })
  .catch(() => {
    // not much we can do about that
    console.error('Sentry initialization failed')
  })
