type PromiseResolve<T> = (value: T | PromiseLike<T>) => void
type PromiseReject = (reason?: unknown) => void
type PromiseState = 'pending' | 'resolved' | 'rejected'

export type Deferred<T> = {
  promise: Promise<T>
  resolve: PromiseResolve<T>
  reject: PromiseReject
  state: PromiseState
}

type CreateDeferredParams = {
  /**
   * Ignore unhandled rejections. Defaults to `false`
   *
   * By default, Node.js fails with an `UnhandledPromiseRejection` if no one catches a promise.
   * This flag adds a catch handler that does nothing, so that the promise is not considered unhandled.
   */
  ignoreUnhandledRejection?: boolean
}

/**
 * Create a deferred. A deferred is basically a promise resolvable from outside of its handler.
 */
export function createDeferred<T>({ ignoreUnhandledRejection = false }: CreateDeferredParams = {}): Deferred<T> {
  const deferred: Deferred<T> = {
    state: 'pending',
    promise: DUMMY_PROMISE as Promise<T>,
    resolve: DUMMY_RESOLVE,
    reject: DUMMY_REJECT,
  }

  deferred.promise = new Promise<T>((resolve_, reject_) => {
    deferred.resolve = (value) => {
      deferred.state = 'resolved'
      resolve_(value)
    }
    deferred.reject = (reason) => {
      deferred.state = 'rejected'
      reject_(reason)
    }
  })

  // The promise executor is called right away (seen in the ECMA spec),
  // so we know that resolve and reject are set. This is only to make ts happy.
  // (https://262.ecma-international.org/6.0/#sec-promise-executor - point 9.)
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  if (deferred.promise === DUMMY_PROMISE || deferred.resolve === DUMMY_RESOLVE || deferred.reject === DUMMY_REJECT) {
    throw new Error('Promise executor was not called')
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  if (ignoreUnhandledRejection) deferred.promise.catch(() => {})

  return deferred
}

const DUMMY_PROMISE = new Promise(() => {})
const DUMMY_RESOLVE = () => {}
const DUMMY_REJECT = () => {}
