import type { Product } from '@orus.eu/product'
import { BaseDimension } from './abstract-dimension.js'
import { BooleanDimension } from './boolean-dimension.js'
import type { ActivableProductInformationId } from './quote-offer-information-dimension.js'

/**
 * Encapsultate data required to display in the product selector step
 */
export type PossibleOffers = {
  [key in ActivableProductInformationId]?: PossibleOffer
}

export const availableRiskCarrierProductsDimension = new BaseDimension<'availableRiskCarrierProducts', Product[]>({
  name: 'availableRiskCarrierProducts',
  displayValues: { name: 'Produits réassureurs disponibles' },
} as const)

export const rcphAvailableDimension = new BooleanDimension<'rcphAvailable'>({
  name: 'rcphAvailable',
  displayValues: { name: 'RC Pro hiscox disponible' },
} as const)

export const rcphCyberAvailableDimension = new BooleanDimension<'rcphCyberAvailable'>({
  name: 'rcphCyberAvailable',
  displayValues: { name: 'RC Pro hiscox option cyber disponible' },
} as const)

export const rcphTdmiAvailableDimension = new BooleanDimension<'rcphTdmiAvailable'>({
  name: 'rcphTdmiAvailable',
  displayValues: { name: 'RC Pro hiscox option tdmi disponible' },
} as const)

export const rcphMrpSmallOfficeAvailableDimension = new BooleanDimension({
  name: 'rcphMrpSmallOfficeAvailable',
  displayValues: { name: 'RC Pro Hiscox option small MRP disponible' },
} as const)

export const mrpAvailableDimension = new BooleanDimension<'mrpAvailable'>({
  name: 'mrpAvailable',
  displayValues: { name: 'Un produit MRP est disponible' },
} as const)

export const mutaAvailableDimension = new BooleanDimension<'mutaAvailable'>({
  name: 'mutaAvailable',
  displayValues: { name: 'Mutuelle Acheel disponible' },
} as const)

export type PossibleOffer = {
  isRecommended: boolean
}

export const possibleOffersDimension = new BaseDimension<'possibleOffers', PossibleOffers>({
  name: 'possibleOffers',
  displayValues: { name: 'Produits disponibles' },
} as const)
