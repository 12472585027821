import styled from '@emotion/styled'
import { memo, useEffect, useMemo, useRef } from 'react'
import { borderRadius, colorTokens } from '../../foundation'

export type OrusCheckboxSize = 'small' | 'medium'

type CheckboxProps = {
  checked: boolean
  onChange: (checked: boolean) => void
  indeterminate?: boolean
  disabled?: boolean
  size: OrusCheckboxSize
  className?: string
  id?: string
}

export const Checkbox = memo<CheckboxProps>(function Checkbox(props) {
  const { checked, indeterminate, onChange, disabled, size = 'medium', className, ...otherProps } = props

  const inputRef = useRef<HTMLInputElement>(null)

  // SVG of the check or indeterminate, we should put this in a pseudo element for accessibility concerns so it doesn't show up in the DOM
  const iconUrl = useMemo(() => getIconUrl(size, !!indeterminate), [size, indeterminate])

  useEffect(() => {
    if (!inputRef.current) return
    inputRef.current.indeterminate = indeterminate ? true : false
  }, [indeterminate])

  return (
    <Input
      ref={inputRef}
      type="checkbox"
      checkboxSize={size}
      checked={checked}
      onChange={() => onChange(!checked)}
      disabled={disabled}
      iconUrl={iconUrl}
      className={className}
      id={props.id}
      tabIndex={disabled ? -1 : 0}
      {...otherProps}
    />
  )
})

const Input = styled.input<{
  checkboxSize: OrusCheckboxSize
  iconUrl: string
}>`
  --size: ${({ checkboxSize }) => (checkboxSize === 'small' ? '16px' : '24px')};
  --empty-bg-color: ${colorTokens['color-bg-base-normal']};
  --empty-border-color: ${colorTokens['color-stroke-base']};
  --selected-bg-color: ${colorTokens['color-bg-base-active']};
  --selected-border-color: transparent;
  --selected-outline: none;

  outline-offset: 0;
  flex: 0 0 auto;
  width: var(--size);
  height: var(--size);
  margin: 0;
  border-radius: ${borderRadius['10']};
  border: var(--border-stroke-20, 1px) solid var(--empty-border-color);
  box-sizing: border-box;
  overflow: hidden;
  background: var(--empty-bg-color);
  appearance: none;
  outline: none;
  cursor: pointer;

  &:focus-visible {
    --empty-bg-color: ${colorTokens['color-bg-base-focus']};
    --empty-border-color: ${colorTokens['color-stroke-base-focus']};
    --selected-bg-color: ${colorTokens['color-bg-base-active']};
    --selected-border-color: ${colorTokens['color-stroke-base-focus']};
    --selected-outline: none;
  }

  &:disabled {
    --empty-bg-color: ${colorTokens['color-bg-base-disable']};
    --empty-border-color: ${colorTokens['color-stroke-base-disable']};
    --selected-bg-color: ${colorTokens['color-bg-base-disable']};
    --selected-border-color: ${colorTokens['color-stroke-base-disable']};
    --selected-outline: none;
    cursor: default;
  }

  &:hover {
    --empty-bg-color: ${colorTokens['color-bg-base-hover']};
    --empty-border-color: ${colorTokens['color-stroke-base-hover']};
    --selected-bg-color: ${colorTokens['color-bg-base-active']};
    --selected-border-color: transparent;
    --selected-outline: 4px solid ${colorTokens['color-bg-base-tertiary']};
  }

  &:checked,
  &:indeterminate {
    border-color: ${colorTokens['color-stroke-base-selected']};
    outline: var(--selected-outline);
  }

  &:checked:focus-visible,
  &:indeterminate:focus-visible {
    border-color: ${colorTokens['color-stroke-base-focus']};
  }

  .checkbox-container:hover &:not(:disabled) {
    --empty-bg-color: ${colorTokens['color-bg-base-hover']};
    --empty-border-color: ${colorTokens['color-stroke-base-hover']};
    --selected-bg-color: ${colorTokens['color-bg-base-active']};
    --selected-border-color: transparent;
    --selected-outline: 4px solid ${colorTokens['color-bg-base-tertiary']};
  }

  &::before {
    content: ${({ iconUrl }) => `url(${iconUrl})`};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    visibility: hidden;
    background-color: var(--selected-bg-color);
  }

  &:checked::before,
  &:indeterminate::before {
    visibility: visible;
  }
`

const getIconUrl = (size: OrusCheckboxSize, indeterminate: boolean) => {
  if (indeterminate) {
    const indeterminateIconWidth = size === 'small' ? '8px' : '12px'
    const indeterminateIconHeight = size === 'small' ? '7px' : '10px'
    return `"data:image/svg+xml, <svg width='${indeterminateIconWidth}' height='${indeterminateIconHeight}' viewBox='0 0 12 2' xmlns='http://www.w3.org/2000/svg'><rect width='12' height='2' rx='1' fill='white'/></svg>"`
  } else {
    const checkIconWidth = size === 'small' ? '9px' : '14px'
    const checkIconHeight = size === 'small' ? '9px' : '9px'
    return `"data:image/svg+xml, <svg width='${checkIconWidth}' height='${checkIconHeight}' viewBox='0 0 16 12' xmlns='http://www.w3.org/2000/svg'><path fill='white' d='M14.7104 1.20998C14.6175 1.11625 14.5069 1.04186 14.385 0.991091C14.2632 0.940323 14.1324 0.914185 14.0004 0.914185C13.8684 0.914185 13.7377 0.940323 13.6159 0.991091C13.494 1.04186 13.3834 1.11625 13.2904 1.20998L5.84044 8.66998L2.71044 5.52998C2.61392 5.43674 2.49998 5.36343 2.37512 5.31423C2.25026 5.26502 2.11694 5.24089 1.98276 5.24321C1.84858 5.24553 1.71617 5.27426 1.59309 5.32776C1.47001 5.38125 1.35868 5.45846 1.26544 5.55498C1.1722 5.6515 1.09889 5.76545 1.04968 5.8903C1.00048 6.01516 0.976347 6.14848 0.978669 6.28266C0.98099 6.41684 1.00972 6.54925 1.06321 6.67233C1.1167 6.79541 1.19392 6.90674 1.29044 6.99998L5.13044 10.84C5.2234 10.9337 5.334 11.0081 5.45586 11.0589C5.57772 11.1096 5.70843 11.1358 5.84044 11.1358C5.97245 11.1358 6.10316 11.1096 6.22502 11.0589C6.34687 11.0081 6.45748 10.9337 6.55044 10.84L14.7104 2.67998C14.8119 2.58634 14.893 2.47269 14.9484 2.34619C15.0038 2.21969 15.0324 2.08308 15.0324 1.94498C15.0324 1.80688 15.0038 1.67028 14.9484 1.54378C14.893 1.41728 14.8119 1.30363 14.7104 1.20998Z'/></svg>"`
  }
}
