import { ensureError } from '@orus.eu/error'
import { FunctionnalProblemError, InvalidLinkError, NotFoundError, TemporaryProblemError } from '@orus.eu/pharaoh'
import { type ErrorComponentProps } from '@tanstack/react-router'
import { TRPCClientError } from '@trpc/client'
import { logger } from '../../lib/logger'
import { TrpcErrorHandler } from '../../lib/TrpcErrorhandler'
import { CrashPage } from '../organisms/crash-page'
import { GenericProblemMessage } from '../organisms/generic-problem-message'
import { InvalidLinkMessage } from '../organisms/invalid-link-message'
import { NotFound } from '../organisms/not-found'
import TemporaryFailureScreen from '../organisms/temporary-failure-screen'

export function RootErrorBoundary(props: ErrorComponentProps): JSX.Element {
  const error = ensureError(props.error)

  if (error instanceof TRPCClientError) {
    return <TrpcErrorHandler err={error} />
  }

  if (error instanceof TemporaryProblemError) {
    return <TemporaryFailureScreen />
  }

  if (error instanceof FunctionnalProblemError) {
    return <GenericProblemMessage {...error.params} />
  }

  if (error instanceof NotFoundError) {
    return <NotFound />
  }

  if (error instanceof InvalidLinkError) {
    return <InvalidLinkMessage />
  }

  logger.error(error)
  console.log('setting logger error', error)
  return <CrashPage />
}
