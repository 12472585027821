import { css, type SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { createLink } from '@tanstack/react-router'
import { forwardRef, memo } from 'react'
import { Icon, Text } from '../../components'
import type { IconName } from '../../components/atoms/icon/names'
import { borderRadius, colorTokens, spacing } from '../../foundation'

type NavbarLineSize = 'small' | 'normal'

type NavbarLineState = 'default' | 'hover' | 'active'

export type NavbarLineProps = {
  size: NavbarLineSize
  title: string
  icon?: IconName
  ariaLabel?: string
  onClick?: () => void
  forceActive?: boolean
  forceInactive?: boolean
  forcedHref?: string
  className?: string

  'data-testid'?: string
  'data-status'?: 'active' | undefined
}

export const NavbarLine = createLink(
  memo(
    forwardRef<HTMLButtonElement, NavbarLineProps>(function NavbarLine(props: NavbarLineProps, ref) {
      const {
        size,
        icon,
        ariaLabel,
        title,
        'data-testid': dataTestId,
        onClick,
        forceActive,
        forceInactive,
        forcedHref,
        ...otherProps
      } = props

      const isActive = forceActive ? true : forceInactive ? false : otherProps['data-status'] === 'active'

      const renderedIcon = icon ? (
        isActive ? (
          <Icon icon={`${icon}-solid`} size="30" color={colorTokens['color-fg-base-active-inverse']} />
        ) : (
          <Icon icon={`${icon}-regular`} size="30" />
        )
      ) : null

      if (forcedHref) {
        return (
          <StyledLink
            size={size}
            isActive={isActive}
            aria-label={ariaLabel ?? title}
            data-testid={dataTestId}
            navbarLineStylePerState={navbarLineStylePerState}
            href={forcedHref}
            target="_blank"
          >
            {renderedIcon}
            <Text variant={size === 'small' ? 'body2Medium' : 'body1Medium'}>{title}</Text>
          </StyledLink>
        )
      } else {
        return (
          <StyledButton
            ref={ref}
            size={size}
            isActive={isActive}
            aria-label={ariaLabel ?? title}
            data-testid={dataTestId}
            navbarLineStylePerState={navbarLineStylePerState}
            onClick={onClick}
            {...otherProps}
          >
            {renderedIcon}
            <Text variant={size === 'small' ? 'body2Medium' : 'body1Medium'}>{title}</Text>
          </StyledButton>
        )
      }
    }),
  ),
)

const getStyledNavLineMixin: (
  size: NavbarLineSize,
  navbarLineStylePerState: NavbarLineStylesPerState,
  isActive: boolean,
) => SerializedStyles = (size, navbarLineStylePerState, isActive) => css`
  display: flex;
  width: 100%;
  max-width: 320px;
  flex-shrink: 0;
  flex-grow: 1;

  align-items: center;
  gap: ${spacing[50]};
  padding: ${size === 'small' ? spacing[30] : spacing[40]} ${spacing[50]};

  outline: none;
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: inherit;
  text-align: left;

  border-radius: ${borderRadius['30']};

  transition: background-color 0.25s ease-in-out;

  &:disabled {
    pointer-events: none;
    box-shadow: none !important;
  }

  ${navbarLineStylePerState.default}

  &:hover {
    ${navbarLineStylePerState.hover}
  }

  ${isActive ? navbarLineStylePerState.active : null}

  &:focus {
    ${navbarLineStylePerState.active}
  }
`

const StyledButton = styled.button<{
  size: NavbarLineSize
  navbarLineStylePerState: NavbarLineStylesPerState
  isActive: boolean
}>`
  ${({ size, isActive, navbarLineStylePerState }) => getStyledNavLineMixin(size, navbarLineStylePerState, isActive)}
`

const StyledLink = styled.a<{
  size: NavbarLineSize
  navbarLineStylePerState: NavbarLineStylesPerState
  isActive: boolean
}>`
  ${({ size, isActive, navbarLineStylePerState }) => getStyledNavLineMixin(size, navbarLineStylePerState, isActive)}
`

type NavbarLineStylesPerState = Record<NavbarLineState, { backgroundColor: string; border: string }>

const navbarLineStylePerState: NavbarLineStylesPerState = {
  default: { backgroundColor: 'transparent', border: 'none' },
  hover: { backgroundColor: colorTokens['color-bg-base-secondary-hover'], border: 'none' },
  active: { backgroundColor: colorTokens['color-bg-base-secondary-active'], border: 'none' },
}
