import { useCallback, useState } from 'react'

/**
 * The useCrash() hook allows sending error to the error boundary from outside react components
 * rendering functions (the error boundary only catch errors thrown inside react components).
 */
export function useCrash(): (error: Error) => void {
  const [, setState] = useState()

  const crash = useCallback((error: Error) => {
    setState(() => {
      throw error
    })
  }, [])

  return crash
}

/**
 * Use TemporaryProblemError for temporary problems, like a network failure, or a 3rd party service being down.
 * The error boundary will display an appropriate message to the user, inviting them to retry.
 */
export class TemporaryProblemError extends Error {}

/**
 * Use NotFoundError when you identify that the resource cannot be found outside of a trpc query.
 * The error boundary will display an appropriate message to the user.
 */
export class NotFoundError extends Error {}

/**
 * Use FunctionnalProblemError to display an expected functionnal problem that need to be communicated to the user
 * in the form of a crash page.
 */
export class FunctionnalProblemError extends Error {
  readonly params: FunctionnalProblemErrorParams

  constructor(params: FunctionnalProblemErrorParams) {
    super()
    this.params = params
  }
}

export type FunctionnalProblemErrorParams = {
  title: string
  principalMessage: string
  firstSubText: string
  secondSubText: string
  trackingId?: string
  buttonText?: string
  onButtonClick?: () => void
}

/**
 * Use InvalidLinkError to indicate to the user that the link they clicked on is invalid and was
 * probably badly copied.
 */
export class InvalidLinkError extends Error {}
