import { createDeferred } from '@orus.eu/deferred'
import { TechnicalError } from '@orus.eu/error'
import { newSubscribable } from '@orus.eu/observable'
import type { OperatingZone } from '@orus.eu/operating-zone'

export const hubspotWidgetWrapperId = 'hubspot-chat-widget'

export const hubspotWidgetOpen = newSubscribable<boolean>(false)

export const chatEnabledForZone: Record<OperatingZone, boolean> = {
  fr: true,
  es: false,
}

export function openHubspotChat(): void {
  // close any open popup before displaying the chat
  window.location.hash = ''
  hubspotWidgetOpen.setValue(true)
  ;(async () => {
    const hubspotWidgetApi = await ensureHubspotLoaded()
    hubspotWidgetApi.load()
  })().catch(console.error)
}

export function closeHubspotChat(): void {
  hubspotWidgetOpen.setValue(false)
}

type HubSpotWidgetApi = Exclude<Window['HubSpotConversations'], undefined>['widget']

let hubspotLoadPromise: Promise<HubSpotWidgetApi> | undefined

async function ensureHubspotLoaded(): Promise<HubSpotWidgetApi> {
  if (window.HubSpotConversations) return window.HubSpotConversations.widget
  if (hubspotLoadPromise) return hubspotLoadPromise

  const hubspotReadyDeferred = createDeferred<HubSpotWidgetApi>()
  hubspotLoadPromise = hubspotReadyDeferred.promise
  window.hsConversationsOnReady = [
    () => {
      if (!window.HubSpotConversations) throw new TechnicalError('HubSpotConversations?.widget should have been loaded')

      hubspotReadyDeferred.resolve(window.HubSpotConversations.widget)
    },
  ]

  console.log('Loading HubSpot widget...')

  const script = document.createElement('script')
  script.setAttribute('id', 'hs-script-loader')
  script.setAttribute('type', 'text/javascript')
  script.setAttribute('src', 'https://js-eu1.hs-scripts.com/25403169.js')
  document.body.appendChild(script)

  await hubspotLoadPromise
  console.log('HubSpot widget loaded')

  return hubspotLoadPromise
}

export function getHubspotContactLink(email: string): string {
  const url = new URL('https://app-eu1.hubspot.com/contacts/25403169/objects/0-1/views/all/list')
  url.searchParams.append('query', email)
  return url.toString()
}
