import { LinearProgress, styled } from '@mui/material'
import { memo } from 'react'
import { colorTokens } from '../../foundation'
import { defaultProgressBarHeight, useTransitionHelper } from './ProgressBar.util'

export type ProgressBarProps = {
  progress: number
  initialProgress: number
  progressBarHeight?: number
  className?: string
}

export const ProgressBar = memo<{
  initialProgress: number
  progress?: number
  progressBarHeight?: number
  className?: string
}>(function ProgressBar({ initialProgress, progress = 0, progressBarHeight = defaultProgressBarHeight, className }) {
  const progressBarValue = initialProgress + (100 - initialProgress) * progress
  const animatedValue = useTransitionHelper(transitionId, progressBarValue)

  return (
    <StyledProgressBar
      color="primary"
      variant="determinate"
      height={progressBarHeight}
      value={animatedValue}
      className={className}
    />
  )
})

const transitionId = Symbol('ProgressBar')

const StyledProgressBar = styled(LinearProgress)<{ height: number }>`
  height: ${({ height }) => height}px;

  &.MuiLinearProgress-root {
    background-color: rgb(from ${colorTokens['color-bg-base-active']} r g b / 20%);
  }

  .MuiLinearProgress-bar {
    background-color: ${colorTokens['color-bg-base-active']};
  }
`
