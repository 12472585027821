export const spacing = {
  5: '1px',
  10: '2px',
  20: '4px',
  30: '8px',
  40: '12px',
  50: '16px',
  60: '24px',
  70: '32px',
  80: '48px',
  90: '64px',
  100: '80px',
} as const

export type Spacing = keyof typeof spacing
