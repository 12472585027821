import {
  defaultFinancialRateConfig,
  financialRateToNumber,
  isFinancialRateInRange,
  type FinancialRate,
  type FinancialRateConfiguration,
} from '@orus.eu/amount'
import { DOCUMENTATION_LANGUAGE, m, type FieldSpecification, type ValueDocumentation } from '@orus.eu/message'
import { success, type Result } from '@orus.eu/result'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionOptions,
  type DimensionTrackingMessageFieldAdapter,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export class FinancialRateDimension<NAME extends string> extends AbstractDimension<NAME, FinancialRate> {
  readonly configuration: FinancialRateConfiguration

  constructor(options: DimensionOptions<NAME> & { configuration?: FinancialRateConfiguration }) {
    super(options)
    this.configuration = options.configuration ?? defaultFinancialRateConfig
  }

  override validateData(value: LooselyTypedValue): Result<FinancialRate, DimensionValidationProblem> {
    if (typeof value !== 'object' || value === null)
      return dimensionValidationFailure(`Field ${this.name} is not an FinancialRate`)
    if (!('__encodedRate' in value))
      return dimensionValidationFailure(
        `Field ${this.name} is not an FinancialRate because property "__encodedRate" is missing`,
      )
    const { __encodedRate } = value
    if (typeof __encodedRate !== 'number') {
      return dimensionValidationFailure(
        `Field ${this.name} is not an FinancialRate because property "__encodedRate" is not a number`,
      )
    }
    if (!Number.isSafeInteger(__encodedRate)) {
      return dimensionValidationFailure(
        `Field ${this.name} is not an FinancialRate because property "__encodedRate" is not a safe integer`,
      )
    }

    if (!isFinancialRateInRange({ __encodedRate }, this.configuration)) {
      return dimensionValidationFailure(
        `Field ${this.name} is not an FinancialRate because property "__encodedRate" is not in range`,
      )
    }

    return success({ __encodedRate })
  }

  override readonly trackingMessageFieldAdapter: DimensionTrackingMessageFieldAdapter = {
    getTrackingFieldSpecification: (): FieldSpecification => {
      const valueDocumentation: ValueDocumentation<number> = {
        title: this.displayNames[DOCUMENTATION_LANGUAGE],
      }
      if (this.hints) {
        valueDocumentation.detailedExplanationMd = this.hints[DOCUMENTATION_LANGUAGE]
      }
      return m.nullable(m.number(valueDocumentation))
    },
    convertStateValueToTrackingValue: (stateValue: FinancialRate | null | undefined): number | null => {
      if (!stateValue) return null
      return financialRateToNumber(stateValue)
    },
  }
}
